import utils from '../common/components/utils';
import TableFilter from '../entity/components/tableFilter.js'
import TypeKind from '../common/enums/typeKind'
import MultilingualString from '../common/models/multilingualString.js';
import CheckboxInput from '../common/components/checkboxInput.js';
import { calcFixedTopOffset } from '../common/components/utils.js';
import FieldSelect from '../common/components/fieldSelect.js';
import DayOfWeekSelect from '../entity/components/dayOfWeekSelect.js';
import FieldInput from '../common/components/fieldInput.js';
import MultilingualInputField from '../common/components/multilingualInputField.js';
import MultilingualHtmlInputField from '../common/components/multilingualHtmlInputField.js';
import FieldTemporalAmountInput from '../entity/components/fieldTemporalAmountInput.js';
import FieldTemporalAccesorInput from '../entity/components/fieldTemporalAccesorInput.js';
import FieldDynamic from '../common/components/fieldDynamic.js';
import CollectionTable from '../entity/components/collectionTable.js';
import FieldKind from '../common/enums/fieldKind';
import PrimitiveEntityType from '../common/enums/primitiveEntityType';
import BinaryInput from '../common/components/binaryInput';
import StateRecovery from '../common/components/stateRecovery'

export function initFiltersEntityTable (op) {
  if (!op.el.length || op.el.is('#gridBuilder *')) {
    return
  }
  op.table.filters = new TableFilter({
    el: op.el,
    table: op.table.oldTable,
    field: op.table.fieldName,
    isField: op.table.isField || false,
    fields: op.fields,
    types: op.types,
    model: op.model,
    typeId: op.typeId
  })
  const filters = op.el
  const table = op.table
  filters.on('filter-changed', () => {
    table.state.setFilters(table.filters.toJSON())
    table.filtersChanged({event: 'filter-changed', filters: table.filters.toJSON()})
  })

  filters.on('filter-removed', () => {
    table.state.setFilters(table.filters.toJSON())
    table.filtersChanged({event: 'filter-removed', filters: table.filters.toJSON()})
  })

  filters.on('all-filters-removed', () => {
    table.state.setFilters(table.filters.toJSON())
    table.filtersChanged({event: 'all-filters-removed', filters: table.filters.toJSON()})
  })

  filters.on('filters-loaded', () => {
    table.state.setFilters(table.filters.toJSON())
    table.filtersChanged({event: 'filters-loaded', filters: table.filters.toJSON()})
  })
  table.filters.render()
}

export function getComparator (fieldName, tableId) {
  const tableType = app.fields.get(tableId).type()
  let field = tableType.fieldByName(fieldName)
  if (!field) {
    field = tableType.fields().find((f) => {return f.id == fieldName})
  }
  if (field.isCollection()){
    return (a, b) => 0
  }
  if (field.isDynamic()){
    return null
  }

  let type = field.type()
  if (type.isPrimitive()){
    let entityType = type.primitive()
    switch (type.primitive()) {
      case PrimitiveEntityType.BOOLEAN:
        return compareBoolean
      case PrimitiveEntityType.INTEGER:
      case PrimitiveEntityType.DOUBLE:
      case PrimitiveEntityType.DECIMAL:
        return compareNumbers
      case PrimitiveEntityType.SYSTEM_STRING:
        return compareSystemString
      case PrimitiveEntityType.STRING:
        return compareString
      case PrimitiveEntityType.BINARY:
        return compareBinaryByFileName
      case PrimitiveEntityType.TIMESTAMP:
      case PrimitiveEntityType.LOCAL_DATE:
      case PrimitiveEntityType.LOCAL_TIME:
      case PrimitiveEntityType.LOCAL_DATE_TIME:
      case PrimitiveEntityType.DAY_OF_WEEK:
      case PrimitiveEntityType.MONTH:
      case PrimitiveEntityType.MONTH_DAY:
      case PrimitiveEntityType.YEAR:
      case PrimitiveEntityType.YEAR_MONTH:
      case PrimitiveEntityType.ZONE_OFFSET:
        return compareInstant
      case PrimitiveEntityType.PERIOD:
        return comparePeriod
      case PrimitiveEntityType.DURATION:
        return compareDuration
    }
  }
  return null
}

export function compareBoolean (a, b) {
  if (!a && b) return -1
  if (a && !b) return 1
  return 0
}

export function compareNumbers (a, b) {
  if (!b || (a && (a < b))) return -1
  if (!a || (a > b)) return 1
  return 0
}

export function compareSystemString (a, b) {
  if (!a) return 1
  if (!b) return -1
  return a.localeCompare(b)
}

export function compareString (a, b) {
  if (a.isBlank()) return 1
  if (b.isBlank()) return -1
  return a.getCurrentValue().localeCompare(b.getCurrentValue())
}

export function compareInstant (a, b) {
  if (!a) return 1
  if (!b) return -1
  return a.compareTo(b)
}

export function comparePeriod (a, b) {
  if (!a) return 1
  if (!b) return -1
  if (a.years < b.years) return -1
  if (a.years > b.years) return 1
  if (a.months < b.months) return -1
  if (a.months > b.months) return 1
  if (a.days < b.days) return -1
  if (a.days > b.days) return 1
  return 0
}

export function compareDuration (a, b) {
  if (!a) return 1
  if (!b) return -1
  if (a.seconds < b.seconds) return -1
  if (a.seconds > b.seconds) return 1
  if (a.nanos < b.nanos) return -1
  if (a.nanos > b.nanos) return 1
  return 0
}

export function compareBinaryByFileName (a, b) {
  if (!a) return 1
  if (!b) return -1
  return a.getFileName().localeCompare(b.getFileName())
}

export function convertSelectionsToIds (selections) {
  return selections
}

export function onCheckAndUncheck (selectionLength, button) {
  if (button.hasClass('disabled')) {
    if (selectionLength) {
      button.removeClass('disabled')
      button.removeAttr('disabled')
    }
  }else {
    if (!selectionLength) {
      button.addClass('disabled')
      button.attr('disabled', 'true')
    }
  }
}

export function setWidthFeature (table, fieldName, width) {
  if (table.tableOp) {
    let columnIndex = _.findIndex(table.tableOp.columns, (column) => {
      return column.field == fieldName
    })
    let column = table.tableOp.columns[columnIndex]
    if (column) {
      column.width = width
    }
    table.tableOp.columns.splice(columnIndex, 1, column)
  }
}

export function updateWidthFeature (table) {
  if (table.tableOp) {
    let columnIndex = 0
    let column = table.tableOp.columns[columnIndex]
    table.tableOp.columns.splice(columnIndex, 1, column)
  }
}

export function updateRelativeOrder (data, reorderPermutations) {
  let relativeOrders = []
  _.each(data, row => {
    relativeOrders.push(row.relativeOrder)
  })
  relativeOrders.sort((a, b) => {
    return a - b})
  _.each(reorderPermutations, (rowNumber, index) => {
    let item = data[rowNumber]
    if (item) {
      item.relativeOrder = relativeOrders[index]
    }
  })
}
export function showColumnsList (e) {
  if (!this.isColumnsListOpened) {
    e.stopPropagation()
    this.isColumnsListOpened = true
    const extendedButton = this._get('extended').first()
    const list = $('<ul class="table-columns-list"></ul>')
    extendedButton.parent().append(list)
    let model = new Backbone.Model()
    let checkboxes = new Map()
    const fixOnlyOne = () => {
      if (this.fieldsToShow.length == 1) {
        checkboxes.get(this.fieldsToShow[0]).disable()
      } else {
        checkboxes.forEach((ch) => {
          ch.enable()
        })
      }
    }
    let getFieldsForShowFromLS = () => {
      return StateRecovery.get(this.state.localStorageKey) && StateRecovery.get(this.state.localStorageKey).fieldsToShow
    }
    let hiddenFieldsAddToStorage = this.columnsFull.filter((col) => {
      return col.showByDefault && col.isHiddenInColumnsList
    }).map(col => col.field) || []
    this.columnsFull.filter(col => col.field).forEach((col) => {
      if (col.isFixed) {
        model.set(col.field, true)
      } else if (!col.isHiddenInColumnsList) {
        const li = $(`<li><span>${col.title}</span></li>`)
        list.append(li)
        const ch = $('<div></div>')
        li.prepend(ch)
        model.set(col.field, this.fieldsToShow.includes(col.field) || (col.showByDefault && !getFieldsForShowFromLS()))
        checkboxes.set(col.field, new CheckboxInput({
          el: ch[0],
          model: model,
          modelAttr: col.field
        }))
      }
    })
    list.css('top', calcFixedTopOffset(0, extendedButton.offset().top + 46, list.height(), -46) + 'px')
    model.on('change', (a) => {
      this.fieldsToShow = this.columnsFull.filter((col) => {
        return model.get(col.field)
      }).map(col => col.field)
      this.fillColumns()
      let fieldsForStorage = this.fieldsToShow
      let localStorageFieldsToShow = getFieldsForShowFromLS()
      if (!localStorageFieldsToShow) {
        fieldsForStorage.push(...hiddenFieldsAddToStorage)
      } else {
        hiddenFieldsAddToStorage.forEach((f) => {
          if (localStorageFieldsToShow.filter((c) => { return c == f }).length) {
            fieldsForStorage.push(f)
          }
        })
      }
      this.state.setFieldsToShow(fieldsForStorage)
      fixOnlyOne()
    })
    fixOnlyOne()
    list.on('click', e => e.stopPropagation())
    $('body').one('click',() => {
      list.remove()
      this.isColumnsListOpened = false
    })
  }
}

export function TreeHelper (rData, comparisonFunction) {
  let rowData = rData.map((r) => {
    r.realId = r.id
    if (r.parent) {
      r.id = r.id + r.parent.id
    }
    return r;
  })
  let compare = comparisonFunction
  var tree = []
  var showData = []
  this.search = function(keyword) {
    redrawTable(undefined , keyword);
  }
  this.update = function () {
    rowData.sort(compare)
    tree = makeTree(rowData)
    let nameCell = $('#FieldsTable2 .header .table-cell:nth-child(2)').addClass('sortable');
    nameCell.removeClass('asc desc');
    redrawTable()
  }
  this.sortState = null;
  this.sort = function(keyword) {
    if (this.sortState == null) {
      this.sortState = 'asc';
    } else if (this.sortState === 'asc') {
      this.sortState = 'desc';
    } else {
      this.sortState = null;
    }
    if (this.sortState == null) {
        this.update();
    } else {
        sortRows(tree, this.sortState, rowData);
    }
    redrawTable(false, keyword)
    let nameCell = $('#FieldsTable2 .header .table-cell:nth-child(2)').addClass('sortable');
    if (this.sortState === 'asc') {
      nameCell.addClass('asc')
    } else if (this.sortState === 'desc') {
      nameCell.removeClass('asc');
      nameCell.addClass('desc')
    } else {
      nameCell.removeClass('desc');
    }
  }

  this.toggleNode = function (id, keyword) {
    toggleTree(tree, id)
    redrawTable(false, keyword)
  }

  this.add = function (data) {
    _.each(data, (d) => {
      d.realId = d.id
      rowData.push(d)
    })
    rowData.sort(compare)
    tree = makeTree(rowData)
    redrawTable()
  }

  this.getShowData = function () {
    return showData
  }
  this.updateRelativeOrder = function (a) {
    updateRelativeOrder(showData, a)
  }
  this.collapseAll = function () {
    collapseAll(tree)
    redrawTable()
  }

  this.hideExpander = function () {
    redrawTable(true)
  }

  this.sortByRelativeOrder = function () {
    rowData = _.sortBy(rowData, 'relativeOrder')
  }

  this.getRow = function (id):object {
    return _.find(rowData, (r) => {
      return r.id == id
    })
  }

  function collapseAll (tr) {
    _.each(tr, (t) => {
      t.expanded = false
      if (t.children.length) {
        collapseAll(t.children)
      }
    })
  }

  function sortRows(rows, order, rowData) {
    let data = _.indexBy(rowData, (row) => row.id + (row.parent ? row.parent.id : ''));
        rows.sort((a, b) => {
            return MultilingualString.getCurrentValue(data[a.id + (a.parent || '')].name).localeCompare(MultilingualString.getCurrentValue(data[b.id + (b.parent || '')].name));
        })
        if (order === 'desc') {
            rows.reverse();
        }

    _.each(rows, (row) => {
      if (row.children.length) {
        sortRows(row.children, order, rowData);
      }
    })
  }

  function redrawTable (noChildren , keyword) {
    showData.splice(0, showData.length)
    _.each(getShowData(tree, rowData, 0, null, 0, noChildren), (a) => {
        if(keyword == null || keyword == ""){
          showData.push(a)
        } else {
          let name = a.name
          if (a.parent){
              name = a.parent.name
          }
          name = MultilingualString.getCurrentValue(name)
          if (name && name.toLowerCase().indexOf(keyword.toLowerCase()) != -1) {
            showData.push(a);
          }
        }
    })
  }

  function findTreeElem (tr, id):object {
    for (let i = 0;i < tr.length;i++) {
      if (tr[i].id == id) {
        return (tr[i])
      }
      if (tr[i].children.length) {
        let t = findTreeElem(tr[i].children, id)
        if (t) {
          return t
        }
      }
    }
    return null
  }

  function toggleTree (tr, id) {
    findTreeElem(tr, id).expanded ^= true
  }

  function getShowData (tr, data, tab, parent, order, noChildren) {
    let res = []
    _.each(tr, (t) => {
      var row = _.find(data, (d) => {
        if (d.parent) {
          return ((d.id == t.id) && (parent == d.parent.id))
        }else {
          return !parent && d.id == t.id
        }
      })
      if (noChildren) {
        row.haveChildren = false
      }else {
        row.haveChildren = Boolean(t.children.length)
      }
      row.expanded = t.expanded
      row.tab = tab
      if (row.parent != null) {
        row.clientId = row.id + '_' + row.parent.id
      }else {
        row.clientId = row.id
      }
      if (row.relativeOrder == undefined || row.relativeOrder == null) {
        row.relativeOrder = order
      }
      order += 1
      res.push(row)
      if (t.expanded && t.children.length && !noChildren) {
        res = res.concat(getShowData(t.children, data, tab + 1, t.id, order))
      }
    })
    return res
  }

  function makeTree (data):object {
    var tTree = []
    _.each(data, (d) => {
      if (_.findIndex(tTree, (t) => {
        return t.id == d.id
      }) != -1) {
        return
      }
      if (d.parent) {
        let parentIndex = _.findIndex(tTree, (t) => {
          return t.id == d.parent.id
        })
        if (parentIndex != -1) {
          tTree[parentIndex].children.push({id: d.id,children: [], parent: d.parent.id})
        }else {
          tTree.push({id: d.parent.id,expanded: false,children: [{id: d.id,expanded: false,children: [], parent: d.parent.id}]})
        }
      }else {
        tTree.push({id: d.id,expanded: false,children: []})
      }
    })
    return tTree
  }
}


export function initEditable (el, row, field, table) {
	var $element = $(el).children();
	var fieldName = field;
	var rowData = table.model.get(row.id);
	var initObject = {
		el: $element,
		model: rowData || new Backbone.Model({}),
		modelAttr: fieldName,
		parentField: table.fieldName,
		context: this.context
	};
	let callback = (model) => {
		rowData.trigger('change:'+ fieldName, model, model.get(fieldName))
	}
	rowData.on('change', callback)
	this.editableClosedCallbacks.push(function() {
		rowData.off('change', callback)
	})
	var item={}
	item.editElements = {};
	switch ($element.prop('tagName').toLowerCase()) {
		case 'select':
			if ($element.attr('data-primitive-type') == PrimitiveEntityType.DAY_OF_WEEK) {
				item.editElements[fieldName] = new DayOfWeekSelect(initObject);
			} else if ($element.attr('data-field-kind') == FieldKind.REGULAR) {
				item.editElements[fieldName] = new FieldSelect(initObject);
			} else if ($element.attr('data-field-kind') == FieldKind.DYNAMIC) {
				item.editElements[fieldName] = new FieldDynamic(initObject);
			}
		break;
		case 'textarea':
		case 'input':
			if ($element.attr('data-is-string-multilingual')) {
				initObject.model = initObject.model || new Backbone.Model();
				let data = initObject.model.get(initObject.modelAttr);
				if (!(data instanceof MultilingualString)) {
					data = new MultilingualString((data && data.toJSON()) || {});
				}
				initObject.model.set(initObject.modelAttr, data);
				if ($element.attr('data-is-string-html') == 'true') {
					initObject.startCollapsed = true;
					item.editElements[fieldName] = new MultilingualHtmlInputField(initObject);
				} else {
					item.editElements[fieldName] = new MultilingualInputField(initObject);
				}
			} else {
				const primitiveType = $element.attr('data-primitive-type');
				switch (primitiveType) {
					case PrimitiveEntityType.MONTH:
					case PrimitiveEntityType.TIMESTAMP:
					case PrimitiveEntityType.LOCAL_DATE:
					case PrimitiveEntityType.LOCAL_TIME:
					case PrimitiveEntityType.LOCAL_DATE_TIME:
					case PrimitiveEntityType.MONTH_DAY:
					case PrimitiveEntityType.YEAR:
					case PrimitiveEntityType.YEAR_MONTH:
						item.editElements[fieldName] = new FieldTemporalAccesorInput(initObject);
						this.editableClosedCallbacks.push(function() {
							item.editElements[fieldName].datepicker.remove()
						})
						break;
					case PrimitiveEntityType.ZONE_OFFSET:
					case PrimitiveEntityType.DURATION:
					case PrimitiveEntityType.PERIOD:
						item.editElements[fieldName] = new FieldTemporalAmountInput(initObject);
						break;
					case PrimitiveEntityType.BOOLEAN:
						initObject.el = initObject.el[0]
						item.editElements[fieldName] = new CheckboxInput(initObject);
						break;
					default:
						item.editElements[fieldName] = new FieldInput(initObject);
						break;
				}
			}
		break;
		case 'div' :
			if ($element.attr('data-primitive-type') == PrimitiveEntityType.BINARY) {
				item.editElements[fieldName] = new BinaryInput(initObject);
			} else if ($element.hasClass('temporal-amount')) {
				item.editElements[fieldName] = new FieldTemporalAmountInput(initObject);
			}
		break;
	}
	this.editing[fieldName]=item.editElements[fieldName];
	item.editElements[fieldName].render();
}
