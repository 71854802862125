import ReferenceView from '../../common/enums/referenceView';
import entityManager from '../../common/components/entityManager.js';
import Entity from '../../common/models/entity.js';
import MultilingualString from '../../common/models/multilingualString';
import {getRowViewItems, fromRowViewItemsToOptions, getOldTableData, formatOrder, getFieldsToShow, getIsGdprSensitiveFields} from '../../utils/rowViewUtils';
import {convertSelectionsToIds, setWidthFeature, updateWidthFeature, initFiltersEntityTable, showColumnsList} from '../../utils/tableUtils';
import TableState from '../../common/components/tableState';
import MetaObjectState from '../../common/enums/metaObjectState.js';
import DocumentRegisterState from '../../common/enums/documentRegisterState';
import { multilingualStringService } from '../../common/service/multilingualStringService';
import utils, { showInstance, buildDefaultPopover, openLink, getListPropValue, scaleDecimals } from '../../common/components/utils'
import BinaryUploaderConnector from '../../common/components/BinaryUploaderConnector'
import Constants from '../../common/models/constants'
import { translate } from '../../common/service/stringResourceService'
import OpenMode from '../../common/enums/openMode.js'
import journalPresenter from '../../entity/views/journalPresenter';
import LocalStorageKeysService from '../../common/service/localStorageKeysService'
import PropertyKey from '../../common/enums/propertyKey'
import MultilingualStringExportMode from '../../common/enums/multilingualStringExportMode';

function fetchPage(moreStructure, table) {
	return entityManager.fetchPageOfRowViews(table.typeId,
	table.viewId, moreStructure).then((object) => {
		let items = [];
		let func = app.userObservers.getRowLoadFunction(object.rowViewId);
		let promises = [];
		if(!app.builderMode){
			object.items.forEach((r) => {
				scaleDecimals(app.types.get(table.typeId), r)
			})
		}
		if (table.context.type.isExternal()) {
			_.each(object.items, item => {
				item.id = table.getNewFakeId()
			})
		}
		if (object.items && func) {
			_.each(object.items, item => {
				let entity = Entity.fromJSON(item, table.context.type.id);
				if (table.context.type.isExternal()){
					entity.cid = entity.get('urlString')
				}
				items.push(entity);
				promises.push(func.call(table.context, entity));
			});
			return Promise.all(promises)
				.then(() => {
					object.items = _.map(items, item => item.toJSON());
					return object;
				});
		} else {
			return Promise.resolve(object);
		}
	}).catch((error) => {
		console.log(error)
		return {
			items: [],
			totalSize:0
		}
	})
}
function getMoreDataForTable(moreStructure,table) {
	table.newTable&&table.newTable.setSelection([])
	let possibleParents = []
	if (table.showAllChildren) {
		possibleParents = table.getAllIdsOfParentsWithChildren()
	}
	$.extend(moreStructure, {
		loadDeleted: table && table.loadDeleted||false,
		filters: table.filters&&table.filters.toServerJSON()||null,
		tags: null,
		lastValue: moreStructure.clear ? null : (table.data[table.data.length-1] || null),
		metaObject: (table.hasMetaObject && table.treeViewKind != 'FLAT' && table.foldersTreeStruct.selectedFolderId) ?
			{id: table.foldersTreeStruct.selectedFolderId} : null,
		parents: possibleParents
	});
	if (moreStructure.loadFilteredSize == true) {
		return entityManager.fetchPageOfRowViews(table.typeId,
		table.viewId, moreStructure).then((object)=> {
			table.setFilteredSize(object.filteredSize)
			table.setTotalSize(object.filteredSize)
			return	{
				items: object.items,
				totalSize: object.filteredSize
			}
		})
	} else {
		return fetchPage(moreStructure, table)
	}

}

function addButtonsHandlers(table){
	table._get('extended').first().on('click', e => {
		table.showColumnsList(e)
	})
	table._get('remove').first().click(() => {
		let ids = table.getSelectedIds()
		if (table.isExternal) {
			ids = ids.map((id) => {
				return table.data.find((d) => {return d.id === id}).urlString
			})
			utils.confirmDelete(ids, app.builderMode ? app.urls.dependencies(table.typeId) : null, app.urls.delete(table.typeId),
			{beforeDelete: 'action.delete.items.question.with.hierarchy'}, () => {
				table.newTable.resetMore()
				table.tree && table.tree.reload();
				table.onCheckAndUncheck();
			});
		} else {
			utils.postRequest(ids, app.urls.hierarhySize(table.typeId)).then(response => {
				let resource = '';
				if (response.size > ids.length) {
					resource = response.fullSize ? 'action.delete.items.question.with.hierarchy.precise' :
						'action.delete.items.question.with.hierarchy';
					resource = multilingualStringService.format(app.getResource(resource), [response.size]);
				}
				utils.confirmDelete(ids, app.builderMode ? app.urls.dependencies(table.typeId) : null, app.urls.delete(table.typeId),
				{beforeDelete: resource}, () => {
					table.newTable.resetMore()
					table.tree && table.tree.reload();
					table.onCheckAndUncheck();
				});
			});
		}
	})

	table._get('nullify').first().click(() => {
		const ids = table.getSelectedIds()
		utils.postRequest(ids, app.urls.nullify(table.typeId)).then(() => {
			table.newTable.resetMore()
			table.tree && table.tree.reload();
			table.onCheckAndUncheck();
		})
	})

	table._get('applyMany').first().confirm({
		confirm: function (button) {
			table.applyMany(table.getSelectedIds());
		}
	});
	table._get('relieveMany').first().confirm({
		confirm: function (button) {
			table.relieveMany(table.getSelectedIds());
		}
	});
	table._get('download').first().click(() => {
		table.download(table.getSelectedIds())
	});

	table._get('export').first().click(() => {
		utils.postRequest({
			view: { id: table.viewId },
			type: { id: table.typeId },
			instances: table.getSelectedIds().map(id => { return {id: id};}),
			properties: {
										header: ReferenceView.STRING_VIEW,
										reference: ReferenceView.STRING_VIEW,
										isExtendedView: true,
										fieldsToShow: table.fieldsToShow.map((f) => {
											return app.types.get(table.typeId).fieldByName(f).id
										}),
										multilingualStringExportMode: MultilingualStringExportMode.CURRENT_LANGUAGE
									}
		}, app.urls.exportSelectedToXLS)
			.then((files) => {
				utils.downloadFile(files);
		});
	});

	table._get('export-gdpr-sensitive').first().click(() => {
		utils.postRequest({
			view: { id: table.viewId },
			type: { id: table.typeId },
			instances: table.getSelectedIds().map(id => { return {id: id};}),
			properties: {
										header: ReferenceView.STRING_VIEW,
										reference: ReferenceView.STRING_VIEW,
										isExtendedView: true,
										fieldsToShow:table.gdprSensitiveFields.map((f) => {
											return app.types.get(table.typeId).fieldByName(f).id
										}),
										multilingualStringExportMode: MultilingualStringExportMode.CURRENT_LANGUAGE
									}
		}, app.urls.exportSelectedToXLS)
			.then((files) => {
				utils.downloadFile(files);
		});
	});

	table._get('fullExport').first().click(() => {
		utils.postRequest({
			fullExport: true,
			type: { id: table.context.type.id },
			instances: table.getSelectedIds().map(id => { return {id: id};}),
			properties: {
										header: ReferenceView.STRING_VIEW_AND_REFERENCE_ID,
										reference: ReferenceView.STRING_VIEW_AND_REFERENCE_ID,
										multilingualStringExportMode: MultilingualStringExportMode.CURRENT_LANGUAGE,
										addCommentWithLinkToDocumentation: true,
										fieldsToShow: []
									}

		}, app.urls.exportSelectedToXLS)
			.then((files) => {
				utils.downloadFile(files);
		});
	});

	table.context.$el.find('.toggle-deleted').click(function () {
		table.loadDeleted = !table.loadDeleted;
		if (table.loadDeleted) {
			table.context.$el.find("a.toggle-deleted").html(app.getResource('hide.deleted'));
		} else {
			table.context.$el.find("a.toggle-deleted").html(app.getResource('show.deleted'));
		}
		table.newTable.resetMore();
	});

	table.context.$el.find('.exportToXLS').click(() => {
		utils.postRequest({
			types: [{
				type: {id: table.typeId}
			}],
			properties: {
				header: ReferenceView.STRING_VIEW_AND_REFERENCE_ID,
				reference: ReferenceView.STRING_VIEW_AND_REFERENCE_ID,
			}
		}, app.urls.exportToXLS)
		.then((files) => {
			utils.downloadFile(files);
		});
	});
}

function fetchRow (id, table) {
	let moreStructure = {
		size: 1,
		loadItems: true,
		loadFilteredSize: false,
		loadTotalSize: false,
		loadDeleted: true,
		filters:[{
			field: {id: "140737488355528"},
			kind: "EQUAL",
			value: id
		}],
		lastValue: null
	}
	return fetchPage(moreStructure, table)
}

function insertNewTable(table,el){
	var that = this
	let op=fromRowViewItemsToOptions(table.rowViewItems,table)
	table.tableOp=op;
	_.each(op.columns,(column) => {
		if (column.isTransient || app.builderMode || column.isCollection) {
			column.sorter = false
		}
	})
	let hasFillFunctions = false;
	if (!table.hideFillFunctions){
		_.each(table.context.fillFunctions || app.fillFunctions, (value) => {
			if (value.owner.id == table.typeId) {
				hasFillFunctions = true;
			}
		});
	}
	if (op.columns.length == 0) {
		op.columns.unshift({
			field: 'id',
			title: translate('id'),
			isSystem: true,
			formatter: (f) => f,
			sorter: false
		})
	}
	if (table.hasMetaObject && !app.builderMode && hasFillFunctions) {
		op.columns.unshift({
			width:42,
			isSystem: true,
			component: {
				implementation: 'cjg-custom',
				data: {
					func: (el, row, field) => {
						let $el = $(el)
						$el.append($(app.formattersAndStyles.fillActionFormatter(null, row, null, table.context.fillFunctions)))
						$el.find('.fill-function').on('click', (e) => {
							let link = $(e.target)
							while (link.prop('tagName').toLowerCase() !== 'button') {
								link = link.parent();
							}
							showInstance({
								fillFunctionId: link.attr('fillfunctionid'),
								fillInstanceId: link.attr('fillinstanceid'),
								typeId: link.attr('fillresulttypeid'),
								openMode: 'new.cj.tab',
								callback: () => {}
							})
						})
					},
					html:"<div></div>"
				}
			}
		})
	}
	if (table.isDocuments && !app.builderMode) {
		if (table.isField && !table.hasPostColumn){
		}else{
			op.columns.unshift({
				width:41,
				isSystem: true,
				formatter:app.formattersAndStyles.actionsFormatter
			})
		}
	}
	if (!table.hideCheckbox){
		op.columns.unshift({type:'checkbox'})
	}
	op.table={}
	op.table.loadMore=(a,b)=>{return getMoreDataForTable({
		size: a.size,
		order: formatOrder(b, table.typeId),
		clear: a.clear,
		loadItems: a.loadItems,
		loadFilteredSize: a.loadTotal,
		loadTotalSize: false
	}, table)}
	op.table.id=table.id
	if (table.id == 'index-table') {
		op.table.id += table.typeId
	}
	if (table.rowHeight) op.table.rowHeight = table.rowHeight
	if (table.headerRowHeight) op.table.headerRowHeight = table.headerRowHeight
	if (table.wrapHeaderText) op.table.wrapHeaderText = table.wrapHeaderText
	table.columnsFull=op.columns
	op.columns=[]
	table.fillColumns()
	op.table.rowClasses=(item,index)=>{
		return{
			deleted:item.metaObjectState == MetaObjectState.DELETED
		}
	}
	table.selected = []
	op.events = {
		selectionChanged: function (s) {
			if (table.isTreeSelectable) {
				table.updateTreeSelection(s)
			}
			table.selected = s
			table.onCheckAndUncheck()
		},
		afterRender: function () {
			console.log('after render');
			table.$el.find('.resize-observer object').attr('tabindex', '-1');
			buildDefaultPopover(table.$el.find('.popover-tooltip'));
			if (table.isTreeSelectable) {
				table.synchronizeTableSelection(table.getTreeSelectedIds())
				table.onCheckAndUncheck()
			}
		}
	}
	if (table.onClickCell) {
		var func = app.userObservers.getRowClickFunction(table.fieldName)
		if (table.isVirtual && func) {
			op.events.rowClicked = function (row, e) {
				let rowModel = new Entity(row, {
					entityTypeId: table.typeId,
					entityViewId: table.viewId
				})
				func.call(table.context, table.context.model, rowModel)
			}
		} else {
			op.events.rowClicked = function (row, e) {
				table.onClickCell(row, e)
			}
		}
		op.table.rowClasses = (item, index) => {
			return {
				deleted: item.metaObjectState == MetaObjectState.DELETED,
				clickable: 'clickable'
			}
		}
	}
	op.componentKey = LocalStorageKeysService.buildKeyForComponent(op.table.id)
	let connector = TableConnector(el, op).connect();
	table.newTable = connector
}

async function newTableInit (options) {

	let errorHandler = (error) => {
		let tableRef = this
		if (error.status == '403') {
			tableRef.context.$el.find(tableRef.tempel$.attr('data-toolbar')).hide()
			tableRef.tempel$.append(`<div style='width:100%;height:150px;border:2px solid #1F8DB8'><h4 style='padding:15px'>${app.getResource("do.not.have.permissions")}</h4></div>`)
		} else {
			throw error
		}
	}

	return getRowViewItems(this.viewId,this.typeId).then((rowViewItems)=>{
		this.rowViewItems=rowViewItems
		this.oldTable=getOldTableData(this.rowViewItems,true)
		initFiltersEntityTable({
			el: this._get('filters').first(),
			table: this,
			fields: app.fields,
			types: app.types,
			model: options.context.model,
			typeId: options.context.type.id
		})
		this.fieldsToShow = getFieldsToShow(rowViewItems)
		this.gdprSensitiveFields = getIsGdprSensitiveFields(rowViewItems)
		if (this.gdprSensitiveFields.length == 0 || !app.permissionChecks.showSettings) {
			this._get('export-gdpr-sensitive').remove()
			this._get('nullify').remove()
		}
		this.state = new TableState(this);
		this.state.apply();
		insertNewTable(this, options.el)
		if (this.disabledProp){
			this.$el.find('.cjg-table').attr('disabled','disabled')
		}
		this.foldersTreeStruct.reload=()=>{this.newTable.resetMore()}
		this.buttonsToEneableOnCheck=[]
		addButtonsHandlers(this)
		this.buttonsToEneableOnCheck.push('applyMany');
		this.buttonsToEneableOnCheck.push('relieveMany');
		this.loadDeleted = false;
		if (!this.isField) {
			let typeId = this.context.type.id;
			this.importDropzone = BinaryUploaderConnector("#importFromXlsUploaderDiv", {
				dzOptions: {
					acceptedFiles: '.xls, .xlsx',
					previewsContainer: false
				},
				events : {
					onSuccess: function (file, response) {
						utils.postRequest([{
							type: {id: typeId},
							file: response
						}], app.urls.importFromXLS).then(() => {
							utils.reload();
						});
					}
				}
			}).connect();

			this.context.$el.find('.importFromXLS').on('click', () => {
				this.importDropzone.openSelectionWindow();
			});
		}
	}).catch(errorHandler)
}

var IndexTable = Backbone.View.extend({

	events: {
		'click .show-registers': 'showRegisters',
		'click button.relieve': 'relieveOne',
		'click button.apply': 'applyOne'
		},

	_get: function (shortId) {
		return this.context.$el.find('.table-' + this.id + '_' + shortId)
	},

	initialize: function(options) {
		this.typeId = options.typeId
		this.fakeId = 0
		this.treeViewKind = options.treeViewKind
		this.extendedView = options.extendedView
		if (getListPropValue(options.listProperties, PropertyKey.SHOW_ALL_CHILDREN)) {
			this.showAllChildren = getListPropValue(options.listProperties, PropertyKey.SHOW_ALL_CHILDREN)
		}
		var loadAllInstancesPromise
		if (this.showAllChildren) {
			loadAllInstancesPromise = this.loadAllInstances(false)
		} else {
			loadAllInstancesPromise = Promise.resolve()
		}
		this.initPromise = loadAllInstancesPromise.then(() =>  {
			this.fullInitialize(options)
		})
	},

	fetchAndAddRow: function (id){
		let index = this.data.findIndex((r) => {
			return r.id == id
		})
		if (index == -1){
			fetchRow(id, this).then((a) => {
				let item = a.items[0]
				if (app.builderMode){
					item = a.items.find((b) => {return b.id == id})
				}
				this.data.unshift(item)
			})
		}
	},

	fetchAndUpdateRow: function (id) {
		let index = this.data.findIndex((r) => {
			return r.id == id
		})
		if (index != -1){
			fetchRow(id, this).then((a) => {
				console.log(index, this.data[index], a.items[0])
				this.data.splice(index, 1, a.items[0])
			})
		}
	},
	getNewFakeId: function () {
		let fakeId = this.fakeId
		this.fakeId++
		return fakeId
	},

	fullInitialize: function (options) {
		options.context = options.context || {
			model: app.model,
			type: app.types.get(app.typeId)
		}
		this.context = options.context;
		this._options = options
		this.hasMetaObject=options.hasMetaObject
		this.isDocuments = options.isDocuments;
		this.viewId=options.viewId
		this.tempel$=$(options.el)
		this.id=this.tempel$.attr('data-table-id')
		this.openMode = getListPropValue(options.listProperties, PropertyKey.OPEN) || this.context.openInstancesMode || OpenMode.NEW_CJ_TAB
		this.createMode = getListPropValue(options.listProperties, PropertyKey.CREATE_NEW_VIEW) || this.context.openInstancesMode || OpenMode.NEW_CJ_TAB
		this.linkFormView = getListPropValue(options.listProperties, PropertyKey.LINK_FORM_VIEW) || options.linkFormView
		this.modalWidth = this.tempel$.attr('data-modal-width')
		this.modalHeight = this.tempel$.attr('data-modal-height')
		this.modalFloat = this.tempel$.attr('data-modal-float')
		if (this.tempel$.attr('data-disabled') == "true"){
			this.disabledProp=true;
		}
		if (this.tempel$.attr('data-is-virtual')){
			this.createMode = this.tempel$.attr('data-create-new-action')
			this.isVirtual = true;
			this.viewId=this.tempel$.attr('data-view-id')
			this.typeId=this.tempel$.attr('data-entity-type-id')
			this.hasPostColumn=this.tempel$.attr('data-has-post-column')
			this.hasFolderTree=this.tempel$.attr('data-has-folder-tree')
			this.onlyFolderTree=this.tempel$.attr('data-use-tree-only')
			this.showAllChildren=this.tempel$.attr('data-show-all-children')
			this.isTreeSelectable=this.tempel$.attr('data-is-tree-selectable')
			this.treeViewKind = this.tempel$.attr('data-tree-view-kind')
			this.openMode=this.tempel$.attr('data-use-viewer') || 'new.cj.tab';
			this.createMode = this.tempel$.attr('data-create-new-action') || 'new.cj.tab';
			this.showAllChildren=this.tempel$.attr('data-show-all-children')
			this._get('add').click(() => this.addInstance());
		} else {
			this._get('add').click(() => this.addInstance());
		}
		this.isExternal = app.types.get(this.typeId).isExternal()
		if (getListPropValue(options.listProperties, PropertyKey.IS_TREE_SELECTABLE)) {
			this.isTreeSelectable = getListPropValue(options.listProperties, PropertyKey.IS_TREE_SELECTABLE)
		}
		if (this.tempel$.attr('data-link-form-view')){
			this.linkFormView=this.tempel$.attr('data-link-form-view');
		}
		this.hideCheckbox = this.tempel$.attr('data-hide-checkbox') || getListPropValue(options.listProperties, PropertyKey.HIDE_CHECKBOX)
		this.hideFillFunctions = this.tempel$.attr('data-hide-fillfunctions') || getListPropValue(options.listProperties, PropertyKey.HIDE_FILLFUNCTIONS)
		this.rowHeight = getListPropValue(options.listProperties, PropertyKey.ROW_HEIGHT) || options.rowHeight
		this.headerRowHeight = getListPropValue(options.listProperties, PropertyKey.HEADER_ROW_HEIGHT) || options.headerRowHeight
		if (!this.headerRowHeight && this.tempel$.attr('data-header-row-height')) {
			this.headerRowHeight = this.tempel$.attr('data-header-row-height')
		}
		if (!this.rowHeight && this.tempel$.attr('data-row-height')) {
			this.rowHeight = this.tempel$.attr('data-row-height')
		}
		this.wrapHeaderText = getListPropValue(options.listProperties, PropertyKey.WRAP_HEADER_TEXT) || options.wrapHeaderText
		if (!this.wrapHeaderText && this.tempel$.attr('data-wrap-header-text')) {
			this.wrapHeaderText = this.tempel$.attr('data-wrap-header-text')
		}
		if (options.onConfirmSelect) {
			this._get('confirm-select').click(() => {
				options.onConfirmSelect(this.getSelectedIds())
			});
		}
		this.modalOpt = {
			modalWidth: this.tempel$.attr('data-modal-width'),
			modalHeight: this.tempel$.attr('data-modal-height'),
			modalFloat: this.tempel$.attr('data-modal-float')
		}

		this.fieldId=this.tempel$.attr('data-field-id')
		this.fieldName = utils.getDataFieldAttr(this.tempel$) || ""
		this.context.control = this.context.control || {} // TODO: remove from here
		this.context.control[this.fieldId || 'indexTable'] = this
		this.tempel$.wrap(`<div class="highViewport newTableWrapper" class="table-${this.id}"><div>`)
		this.setElement(this.tempel$.parent())
		this.data=[]
		this.foldersTreeStruct={
			selectedFolderId: '',
			reload: () => {}
		}
		if (!this.initPromise) {
			this.initPromise = Promise.resolve()
		}
		options.resolveFunc && options.resolveFunc()
	},

	async initializeTableAsync () {
		return this.initPromise.then(() => {
			return newTableInit.call(this, this._options)
		})
	},

	setFilteredSize (size) {
		this.filteredSize = size
		this.filters && this.filters.setFilteredLabelValue(size)
	},

	setTotalSize (size) {

	},

	onCheckAndUncheck: function () {
		var selectionLength = this.newTable.getSelection().length;
		this.changesAfterChangeSelection(selectionLength)
	},

	changesAfterChangeSelection: function (selectionLength) {
		var that = this
		this._get('selectedEntities').text(selectionLength)

		if (selectionLength === 0) {
			this._get('selectedEntities').parent().attr('disabled', 'disabled')
			this._get('remove').addClass('disabled')
		} else {
			this._get('selectedEntities').parent().removeAttr('disabled')
			this._get('remove').removeClass('disabled')
		}

		$(this.buttonsToEneableOnCheck).each(function (i, value) {
			var addDisabledClass = !selectionLength
			that._get(value).toggleClass('disabled', addDisabledClass)
		})
	},


	setWidth: function (fieldName, width) {
		setWidthFeature(this, fieldName, width)
	},

	updateWidth: function () {
		updateWidthFeature(this)
	},

	filtersChanged: function (event) {
		if (this.newTable){
			this.newTable.resetMore();
		}
	},

	getStorageId: function () {
		if (this.extendedView){
			return this.typeId+'_'+this.id+'extendedf'
		} else {
			return this.typeId+'_'+this.id+'f'			
		}
	},

	setFieldsToShow: function (fields) {
		this.fieldsToShow = fields
	},

  fillColumns: function () {
    this.tableOp.columns.splice(0, this.tableOp.columns.length)
    _.each(this.columnsFull, column => {
      if (this.fieldsToShow.includes(column.field) || column.type == 'checkbox' || column.isSystem) {
        this.tableOp.columns.push(column)
      }
    })
  },

	setExtendedMode: function(isExtended){
	},

	getSelectedIds: function(){
		return convertSelectionsToIds(this.newTable.getSelection())
	},

	removeFilters: function () {
		this.filters && this.filters.removeFilters();
	},

	filter: function (filters, strict) {
		if (strict || this.filters && this.filters.getFilters().length === 0 && filters.length !== 0) {
			this.filters.loadFilters(filters);
		}
	},

	onClickCell: function (row,e) {
		let target=$(e.target)
		if (!((target.parents('a')[0])||e.target.nodeName.toLowerCase()=='a')){
			let cell
			if (target.hasClass('table-cell')){
				cell=target
			}else{
				cell=target.parents('div.table-cell')
			}
			if (target.is('.ref')) {
				openLink(target, e);
			} else if (!(cell.find('button')[0])){
					if (!this.context.type.isRegister()) {
						if (this.context.clickCallback) {
							this.context.clickCallback(row);
						} else {
							let id = row.id
							if (this.isExternal) {
								id = row.urlString
							}
							this.showInstance(this.typeId, id, row.entityTypeId, e);
						}
					}
				}
			}
	},

	showInstance(typeId, rowId, rowEntityTypeId, e) {
		showInstance({openMode: this.openMode,
			typeId:	typeId || rowEntityTypeId,
			objectId: rowId,
			viewId: this.linkFormView,
			openInNewTab: e && e.button == 1,
			callback: (data) => {
				if (data && !app.builderMode) {
					if (!this.isExternal){
						this.fetchAndUpdateRow(rowId)
					} else {
						this._updateRow(Object.assign(data.item, data.modifiedItem))
					}
				} else {
					this.newTable.resetMore()
				}
				this.reloadTree()
			},
			onApply: () => {
				this.fetchAndUpdateRow(rowId)
			},
			previousContext: this.context,
			modalOpt: this.modalOpt
		})
	},

	_updateRow (object) {
		let existing = null
		if (this.isExternal){
			existing = this.data.find(row => row.urlString === object.urlString)
		} else {
			existing = this.data.find(row => row.id === object.id)
		}
		if (existing) {
			Object.assign(existing, object)
		}
	},

	applyFilter: function(){
			this.newTable.resetMore()
	},

	showRegisters: function (e) {
		e.stopPropagation();
		var id = $(e.currentTarget).attr('data-document-id');
		if (id) {
			let title;
			app.entityManager.fetchStringView(null, id).then((data) => {
				title = data;
				app.cjTabs.createTab({
					documentId: id,
					title: title + ' ' + translate('postings'),
					onRender: (options) => {
						journalPresenter.present({
							$el: options.$el,
							documentId: id
						})
					}});
			});
		}
	},

	onBodyPost: function (data) {
		buildDefaultPopover($('.popover-tooltip'));
	},

	applyOne: function(e){
		var array = [];
		array.push($(e.currentTarget).attr('id'));
		this.applyMany(array);
	},

	relieveOne: function(e){
		var array = [];
		array.push($(e.currentTarget).attr('id'));
		this.relieveMany(array);
	},

	applyMany: function (ids) {
		utils.postRequest(ids, app.urls.applySelected(this.typeId)).then(() => {
				this.data.forEach((elem) => {	if (ids.includes(elem.id)) elem.documentRegister.state = DocumentRegisterState.POSTED });
			});
	},

	relieveMany: function (ids) {
		utils.postRequest(ids, app.urls.relieveSelected(this.typeId)).then(() => {
				this.data.forEach((elem) => {	if (ids.includes(elem.id)) elem.documentRegister.state = DocumentRegisterState.REVERTED });
			});
	},

	download: function (ids) {
		let itemsArray = _.map(ids, id => _.find(this.data,(a)=>{return a.id==id}));
		let items = new Backbone.Collection();
		items.add(itemsArray);
		const visibleColumns = _.filter(this.tableOp.columns, col => col.field).map(col => col.field);
		const columns = _.filter(this.oldTable.getTableFilterFields(),
			col => col.fieldId && _.contains(visibleColumns, col.field));
		app.csvExport.prepareData(items.models, columns).then(data => {
			let fields = _.map(columns, col => {
				return (new MultilingualString(app.fields.get(col.fieldId).name())).getCurrentValue();
			});
			app.csvExport.exportToCSV(fields, data);
		});
	},

	addInstance() {

		showInstance({openMode: this.createMode,
			typeId: this.typeId,
			viewId: this.linkFormView,
			openInNewTab: this.createMode == 'open.in.new.tab' && this.isVirtual,
			parentId: this.tree && this.tree.selectedFolderId,
			callback: (() => {
				let created = false
				return (data) => {
					if(created) {
						this._updateRow(Object.assign(data.item, data.modifiedItem))
					} else {
						if (this.isExternal){
							let item = Object.assign(data.item, data.modifiedItem)
						 	item.id = this.getNewFakeId()
							this.data.unshift(item)
						}
						this.fetchAndAddRow(data.item.id)
						this.setFilteredSize(this.filteredSize + 1)
					}
					created = true
					this.reloadTree()
				}
			})(),
			onApply: (rowId) => {
				this.fetchAndUpdateRow(rowId)
			},
			previousContext: this.context,
			modalOpt: this.modalOpt
		})
	},

	setTree: function (tree) {
		this.tree = tree;
	},

	reloadTree: function() {
		if(this.tree) {
			this.tree.reload();
		}
	},

	showColumnsList: function(e) {
		showColumnsList.call(this, e)
	},

	disable: function () {
	},

	enable: function () {
	},

	render: function () {
	},

	getTreeSelectedIds: function () {
		return this.foldersTreeStruct.selectedFolderIds || []
	},

	updateTableSelection: function () {
		this.data.forEach(item => {
			if (item) {
				var obj = {
					item: item
				}
				var selected = this.getTreeSelectedIds()
				if (selected.includes(item.id)) {
					obj.value = true
				} else {
					obj.value = false
				}
				this.newTable.checkOne(obj)
			}
		})
		this.selected = this.getSelectedIds()
		this.onCheckAndUncheck(true)
	},

	synchronizeTableSelection: function(treeIds) {

		treeIds.forEach((treeItemId) => {
			var item = this.data.find((i) => { return i.id == treeItemId })
			if (item) {
				var obj = {
					item: item,
					value: true
				}
				this.newTable.checkOne(obj)
			}
		})

		this.data.forEach((instance) => {
			var item = treeIds.find((i) => { return i == instance.id })
			if (!item) {
				var obj = {
					item: instance,
					value: false
				}
				this.newTable.checkOne(obj)
			}
		})

	},

	updateTreeSelection: function (selectedInTable) {

		var selectedInTree = [...this.getTreeSelectedIds()]
		selectedInTable.forEach((id) => {
			if ((!selectedInTree && id) || (selectedInTree && !selectedInTree.includes(id))) {
				this.tree.addToCheckedNodes(id)
			}
		})
		_.without(this.selected,selectedInTable).forEach((id) => {
			if ((!selectedInTable || !selectedInTable.includes(id)) && this.data.find((el) => { return el.id == id })) {
				this.tree.removeFromCheckedNodes(id)
			}
		})

	},

 // Use only for table with tree where tree is with option showAllChildren
	loadAllInstances: function (reloadTable) {
		let url = app.urls.getFolders + '?instanceId=&typeId=' + this.typeId + '&treeRoot=false&wholeTree=true&treeViewKind=' + this.treeViewKind
		return utils.getRequest(url).then((data) => {
			this.allInstancesTree = this.buildMapForTree(data)
			if (reloadTable) {
				this.newTable.resetMore()
			}
		})
	},

	buildMapForTree: function(data) {
			let groupByParent = _.groupBy(data, e => e.parent)
			let groupByParentWithChildren = new Map()
			for (let i in groupByParent) {
				let childrenWithInstances = []
				let children = groupByParent[i]
				if (children.length) {
					children.forEach((child) => {
						if (Object.keys(groupByParent).includes(child.id)) {
							childrenWithInstances.push(child.id)
						}
					})
				}
				if (childrenWithInstances.length) {
					groupByParentWithChildren.set(i, childrenWithInstances)
				}
			}
			return groupByParentWithChildren
	},

	getAllIdsOfParentsWithChildren: function() {
		let selectedId = this.foldersTreeStruct.selectedFolderId
		let result = []
		if (selectedId) {
			result = this.allInstancesTree.get(selectedId.toString())
			let level = result || []
			while (level.length) {
				let newLevel = []
				level.forEach((node) => {
					if (this.allInstancesTree.has(node)) {
						let children = this.allInstancesTree.get(node)
						newLevel = newLevel.concat(children)
					}
				})
				level = newLevel
				result = result.concat(newLevel)
			}
		}
		return result
	}

});

export default IndexTable;
