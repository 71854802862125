import entityManager from '../../common/components/entityManager.js';
import utils from '../../common/components/utils.js';
import FilterModal from './filterModal.js';
import MultilingualString from '../../common/models/multilingualString.js';

import Formatter from '../../common/components/formatter';
import PrimitiveEntityType from '../../common/enums/primitiveEntityType';
import ModelFactory from '../../common/models/modelFactory';
import Constants from '../../common/models/constants';
import FieldFilter from '../../common/models/fieldFilter';
import Entities from '../../common/collections/entities';
import FieldKind from '../../common/enums/fieldKind.js';
import enumsMap from '../../common/enums/enumsMap'

const buttonGroupHtml = () => `
<div class="btn-group" role="group">
	<div class="filter-dropdown btn-group" role="group">
		<button class="add-filter btn btn-default dropdown-toggle" data-toggle="dropdown"
			aria-haspopup="true" aria-expanded="false">
			<span class="glyphicon glyphicon-filter"></span>
			<span class="label filters-count-label"></span>
		</button>
		<ul class="dropdown-menu filter-dropdown-menu" style="padding-top: 0; padding-bottom: 0">
			<li>
				<div class="box box-widget box-nomargin">
					<div class="box-body">
						<div class="row">
							<div class="col-md-12">
								<div class="inline-block">
									<select class="fields" data-width="200"></select>
								</div>
								<div class="dropdown inline-block">
									<button class="select-kind btn btn-default dropdown-toggle" data-toggle="dropdown"
										aria-haspopup="true" aria-expanded="false">
									</button>
									<ul class="dropdown-menu kinds">
									</ul>
								</div>
								<div class="value-block inline-block" style="width: 200px;">
								</div>
							</div>
						</div>
						<div class="row" style="margin-top: 5px">
							<div class="col-md-12">
								<button class="pull-left btn btn-default cancel-filter">${app.getResource('cancel')}</button>
								<button class="pull-right btn btn-primary apply-filter">${app.getResource('apply')}</button>
							</div>
						</div>
					</div>
				</div>
			</li>
		</ul>
	</div>
	<div class="btn-group" role="group">
		<button type="button" class="filter-clear-dropdown btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			<span class="caret"></span>
		</button>
		<ul class="dropdown-menu">
			<li class="disabled remove-filters">
				<a>
					<span class="glyphicon glyphicon-trash"></span>
					<span>${app.getResource('clear.filters')}</span>
				</a>
			</li>
		</ul>
	</div>
</div>`;

const containerHtml = `
	<div class="filters-container" style="display: inline"></div>
`;

var TableFilter = Backbone.View.extend({

	buttonGroupHtml: buttonGroupHtml,
	containerHtml: containerHtml,

	events: {
		'click .remove-filters': 'removeFilters'
	},

	unableToParseFilters: false,

	kindMapToChar: {
		'EQUAL': '=',
		'NOT_EQUAL': '≠',
		'GREATER': '>',
		'GREATER_EQUAL': '≥',
		'LESS': '<',
		'LESS_EQUAL': '≤',
		'LIKE': '∼',
		'NULL': '∅',
		'NOT_NULL': '…'
	},

	kindMapToText () {
	  return {
		'EQUAL': app.getResource('equal'),
		'NOT_EQUAL': app.getResource('not.equal'),
		'GREATER': app.getResource('greater'),
		'GREATER_EQUAL': app.getResource('greater.equal'),
		'LESS': app.getResource('less'),
		'LESS_EQUAL': app.getResource('less.equal'),
		'LIKE': app.getResource('like'),
		'NULL': app.getResource('null'),
		'NOT_NULL': app.getResource('not.null')
	  }
	},

	initialize: function (options) {
		this.fields = options.fields;
		this.currentFilters = []
		this.field = options.field;
		this.table = options.table;
		this.types = options.types;
		this.model = options.model;
		this.typeId = options.typeId;
		this.collection = this.table.getTableFilterFields()
		if (options.isField && this.model.getViewContext()) {
			this.fieldOptionsModel = this.model.getViewContext().getOptionsForField(
				this.types.get(this.typeId).fieldByName(this.field));
			this.filters = this.fieldOptionsModel.get('filters');
			this.listenTo(this.fieldOptionsModel, 'change:filters', () => {
				this.filters = this.fieldOptionsModel.get('filters');
				this.loadFilters(this.filters.toJSON().slice(0));
			});
		} else {
			this.filters = Entities.fromJSON([], Constants.ID_TYPE_FIELD_FILTER);
		}
		this.modal = new FilterModal({
			el: this.$el,
			types: this.types,
			appFields: this.fields,
			tableFilter: this,
			fields: () => this.getFieldsForFilterModal(options.isField),
			dataSource: (field) => {
				if (field.id == Constants.ID_FIELD_USER_ACCOUNT) {
					return new Backbone.Collection(app.users)
				}
				if (field.id == Constants.ID_FIELD_TYPE) {
					return new Backbone.Collection(app.types.models.filter((t) => {return t.kind() == "DOCUMENT"}))
				}
				let	collectioField = this.types.get(this.typeId).fieldByName(this.field);
				if (!collectioField ||
					collectioField.get('fieldKind') != FieldKind.COLLECTION ||
					collectioField.get('isVirtual')) {
					return null;
				}
				let collection = this.model.get(this.field);
				return new Backbone.Collection(collection.models.map(m => m.get(field.fieldName())));
			}
		});
	},

	render: function () {
		this.renderHTML();
		this.modal.render();
		this.drawFilters();
	},

	renderHTML: function () {
		this.$el.html(buttonGroupHtml() + containerHtml);
	},

	drawFilters: function () {
		_.each(this.filters.models, (filter) => {
			if (filter.get('op')) {
			 	return true;
			 }
			this._drawFilter(filter);
		});
	},

	getFieldsForFilterModal: function (isCollectionField) {
		let that = this
		const fields = [];
		const collection = this.collection
		_.each(collection, (column) => {
			const fieldId = column.fieldId
			let field = null
			if (isCollectionField) {
				field = fieldId && that.fields.get(fieldId).toJSON()
			} else {
				field = fieldId && that.fields.get && that.fields.get(fieldId) && !(that.fields.get(fieldId).get('isTransient') && that.table.isIndexTable) && that.fields.get(fieldId).toJSON()
			}
			if (field){
				if (column.name){
					field.name = column.name
				}
				fields.push(field);
			}
		});
		return fields;
	},
//TODO: remove unableParseFilters
	unableParseFilters: function () {
		this._emptyContainer();
		const $span = this._constructFilterViewShell('custom', true);
		this._fillFilterView($span, 'custom', true);
		$span.find('.remove-filter').click(function () {
			that.$('.add-filter').removeClass('disabled');
			$span.remove();
		});
		this.$('.filters-container').append($span);
		this.$('.add-filter').addClass('disabled');
	},
	isOrPresent: function () {
		return !!this.filters.find((f) => {return f.get('op') == 'OR'})
	},
	loadFilters: function (filters) {

		let newFilters = Entities.fromJSON([], Constants.ID_TYPE_FIELD_FILTER);
		if (filters) {
			_.each(filters, (filter) => {

				const Type = ModelFactory.getModelType(this.types.get(Constants.ID_TYPE_FIELD_FILTER));
				const newFilter = new Type();

				if (filter.op) {
					newFilter.set('op', filter.op)
				} else{
					const field = this.fields.get(filter.field.id);
					newFilter.set('field', field);
					newFilter.set('kind', filter.kind);
					newFilter.set('value', FieldFilter.valueFromJSON(field.type(), filter.value));
				}
				newFilter.set('readOnly', filter.readOnly);
				newFilter.set('isVisible', filter.isVisible);
				newFilters.add(newFilter);
			});
		}
		this._removeFilters(true);
		_.each(newFilters.models, (f) => {
			this.addFilter(f, true);
		})
		this.$el.trigger('filters-loaded');
		this.currentFilters = this.filters.toServerJSON()
	},

	reloadFilters() {
		this._clearContainerAndDisableRemove();
		this.filters.each(filter => this.addFilter(filter));
		this.currentFilters = this.filters.toServerJSON()
		this.$el.trigger('filters-loaded');
	},

	disableRemoveFilter: function() {
		this.$('.filter-clear-dropdown').attr('disabled','disabled')
		this.$('.filters-container').addClass("disable-remove")
	},

	allowRemoveFilter: function() {
		this.$('.filter-clear-dropdown').removeAttr('disabled')
		this.$('.filters-container').removeClass("disable-remove")
	},


	changeFilter: function (event) {
		event.stopPropagation();
		const filterId = $(event.currentTarget).attr('data-filter-id');
		const filter = this.filters.get(filterId);
		this.modal.change(filter, $(event.currentTarget));
	},

	setFilteredLabelValue (newCount: Number) {
		this.$('.filters-count-label').text(newCount)
	},

	addFilter: function (filter, silent) {
		let notAdded = true
		if (!this.filters.get(filter.cid)) {
			notAdded = false
			this.filters.add(filter);
		}
		this._drawFilter(filter);
		if (!silent) {
			this.currentFilters = this.filters.toServerJSON()
			this.$el.trigger('filter-changed')
		}
		return notAdded
	},

	_drawFilter(filter) {
		if (!filter.get('isVisible')) {
			return;
		}
		let $span = this.$('.filters-container [data-filter-id = ' + filter.cid + ']');
		if ($span.length !== 0) {
			$span.empty();
		} else {
			$span = this._constructFilterViewShell(filter.cid, filter.get('readOnly'));
			this.$('.filters-container').append($span);
			if (!filter.get('readOnly')) {
				$span.click((e) => this.changeFilter(e));
			}
		}
		this._fillFilterView($span, this._filterTextSpan(filter), filter.get('readOnly'));
		if (!filter.get('readOnly')) {
			$span.find('.remove-filter').click(() => {
				const id = $span.attr('data-filter-id');
				if (this.isOrPresent()) {
					const index = this.filters.findIndex((f)=>{return f.cid == id})
					const op = this.filters.at(index + 1)
					if (op.get('op') == 'AND') {
						this.filters.remove(op, {silent: true})
					}
				}
				this.filters.remove(id);
				if (this.filters.length === 0) {
					this.$('.remove-filters').addClass('disabled');
				}
				this.$el.trigger('filter-removed');
				$span.remove();
			});
		}
		this.$('.remove-filters').removeClass('disabled');
	},

	_constructFilterViewShell: function (filterId, readOnly) {
		var classes = 'closeable-label closeable-label-lg ';
		if (readOnly) {
			classes += 'closeable-label-readonly';
		}
		return $('<span>')
			.addClass(classes)
			.attr('data-filter-id', filterId);
	},

	_fillFilterView: function ($view, text, readOnly) {
		$view.append(text);
		if (!readOnly) {
			$view.append($('<a>').addClass('remove-filter').html('<i class="material-icons notranslate">close</i>'));
		}
	},

	_filterTextSpan: function (filter) {
		let fieldName = filter.get('field').get('name')
		if (this.collection) {
			let column = this.collection.find((c) => { return c.fieldId == filter.get('field').get('id') })
			if (column.name) {
				fieldName = column.name
			}
		}
		const text = '<span class="name-text">' +
				MultilingualString.toHTML(fieldName) + ' ' +
				this.kindMapToChar[filter.get('kind')] + ' </span>' + this.filterValueToString(filter);
		return $('<span>').html(text);
	},

	_getVisibleFiltersCount () {
		return this.filters.where({isVisible:true}).length
	},

	_removeFilters: function (full) {
		let hidden = this.filters.where({isVisible:false})
		this.filters.reset();
		if (!full) {
			this.filters.set(hidden)
		}
		this._clearContainerAndDisableRemove();
	},

	_clearContainerAndDisableRemove() {
		this._emptyContainer();
		this.$('.remove-filters').addClass('disabled');
	},

	_emptyContainer: function () {
		this.$('.filters-container').empty();
	},

	removeFilters: function () {
		this._removeFilters();
		this.$el.trigger('all-filters-removed');
	},

	getFilters: function () {
		return this.filters;
	},

	toJSON() {
		const filters = [];
		this.filters.each((filter) => {
			var newFilter = filter.toJSON();
			filters.push({
				field: filter.get('field') && {
					id: filter.get('field').id
				},
				value: newFilter.value,
				kind: newFilter.kind,
				readOnly: newFilter.readOnly,
				isVisible: newFilter.isVisible,
				op: newFilter.op
			});
		});
		return filters;
	},

	toServerJSON() {
		const filters = [];
		this.filters.each((filter) => {
			var newFilter = filter.toServerJSON();
			newFilter.field = filter.get('field') && {
				id: filter.get('field').id
			};
			filters.push(newFilter);
		});
		return filters;
	},

	disable() {
		this._removeFilters();
		this.$('.add-filter').attr('disabled', 'disabled');
	},

	filterValueToString: function (filter) {
		var fieldType = filter.get('field').get('fieldTypeId') &&
			this.types.get(filter.get('field').get('fieldTypeId'));
		if (filter.get('kind') === 'NULL' || filter.get('kind') === 'NOT_NULL') {
			return '';
		}
		if (fieldType == null || !fieldType.primitive()) {
			return filter.get('value')
					? utils.stringView(
						entityManager.fetchStringView(null, filter.get('value').id),
						null, filter.get('value').id)
					: '';
		} else if (fieldType.primitive() === PrimitiveEntityType.MONTH ||
			fieldType.primitive() === PrimitiveEntityType.TIMESTAMP ||
			fieldType.primitive() === PrimitiveEntityType.LOCAL_DATE ||
			fieldType.primitive() === PrimitiveEntityType.LOCAL_TIME ||
			fieldType.primitive() === PrimitiveEntityType.LOCAL_DATE_TIME ||
			fieldType.primitive() === PrimitiveEntityType.DAY_OF_WEEK ||
			fieldType.primitive() === PrimitiveEntityType.MONTH_DAY ||
			fieldType.primitive() === PrimitiveEntityType.YEAR ||
			fieldType.primitive() === PrimitiveEntityType.YEAR_MONTH ||
			fieldType.primitive() === PrimitiveEntityType.DURATION) {
				return Formatter.formatToHTML(filter.get('value') && filter.get('value').toJSON(), {
					type: fieldType
				});
			} else if(fieldType.primitive() === PrimitiveEntityType.ENUM) {
				let baseEnum = filter.get('baseEnum')
				if (!baseEnum){
					baseEnum = enumsMap[fieldType.toJSON().primitiveTypeProperties.enumName]
				}
				return baseEnum.getAll()[filter.get('value')]
			} else {
			return Formatter.format(filter.get('value'), {
				type: fieldType
			}, null, true);
		}
	}
});

export default TableFilter;
