import entityManager from '../../common/components/entityManager.js';
import utils from '../../common/components/utils.js';
import {unscaleDecimals} from '../../common/components/utils.js';
import {getRowViewItems, fromRowViewItemsToOptions, getOldTableData, getFieldsToShow} from '../../utils/rowViewUtils'
import {setWidthFeature, initFiltersEntityTable, showColumnsList, initEditable} from '../../utils/tableUtils'
import TableState from '../../common/components/tableState';
import ReferenceView from '../../common/enums/referenceView';
import MultilingualString from '../../common/models/multilingualString';
import States from '../../common/enums/states';
import { showConfirmModal } from '../../common/components/confirmModalVue'
import { translate } from '../../common/service/stringResourceService'
import { buildDefaultPopover } from '../../common/components/utils'
import LocalStorageKeysService from '../../common/service/localStorageKeysService'

function addButtonsHandlers(table) {
  table._get('remove').first().on('click', (e) => {
    if (!e.currentTarget.classList.contains('disabled')) {
      showConfirmModal({
        title: translate('delete'),
        text: translate('action.delete.items.question'),
        confirmButtonText: translate('yes'),
        cancelButtonText: translate('no'),
        onConfirm: () => {
          table.removeRows(table.getSelectedIds())
          table.onCheckAndUncheck()
        }
      })
    }
  })
	table._get('applyMany').first().confirm({
		confirm: function (button) {
			table.applyMany(table.getSelectedIds());
		}
	});
	table._get('relieveMany').first().confirm({
		confirm: function (button) {
			table.relieveMany(table.getSelectedIds());
		}
	});
	table._get('add').first().on('click',a=>{
			table.addRow();
	});
	table._get('reorder').first().click(function () {
		table.enableReorderable();
	});
	table._get('saveOrder').first().click(function () {
		table.saveOrder();
		table.disableReorderable();
	});
	table._get('cancelReorder').first().click(function () {
		table.disableReorderable();
	});
	table._get('extended').first().on('click', e => {
		table.showColumnsList(e)
	})

	if (app.builderMode) {
		table._get('export').parent().remove();
	} else {
		table._get('export').first().click(() => {
      var instances = []
      if (table.isEmbedded) {
        instances = table.getSelectedRows()
        instances.forEach((i) => {
          unscaleDecimals(app.types.get(table.typeId), i)
        })
      } else {
        instances = table.getSelectedIds().map((id) => {
          return {id: id}
        })
      }
			utils.postRequest({
						view: { id: table.viewId },
						type: { id: table.typeId },
						instances: instances,
						properties: {
													header: ReferenceView.STRING_VIEW,
													reference: ReferenceView.STRING_VIEW,
													fieldsToShow: table.fieldsToShow.map((f) => {
														return app.types.get(table.typeId).fieldByName(f).id
													}),
													isExtendedView: true,
												}
					},
					(table.isEmbedded
						 ? app.urls.exportSelectedEmbeddedToXLS
						 : app.urls.exportSelectedToXLS)
				)
				.then((files) => {
					utils.downloadFile(files);
			});
		});
	}

	if (app.builderMode) {
		table._get('download').parent().remove();
	} else {
		table._get('download').first().click(() => {
			let itemsArray = table.getSelectedRows();
			let items = new Backbone.Collection();
			items.add(itemsArray);

			const visibleColumns = _.filter(table.tableOp.columns, col => col.field).map(col => col.field);
			const columns = _.filter(table.oldTable.getTableFilterFields(),
				col => col.fieldId && _.contains(visibleColumns, col.field));

			app.csvExport.prepareData(items.models, columns).then(data => {
				let fields = _.map(columns, col => {
					return (new MultilingualString(app.fields.get(col.fieldId).name())).getCurrentValue();
				});
				app.csvExport.exportToCSV(fields, data);
			});
		});
	}
}

function getHTMLByFieldName(tableId,fieldName, context){
	return context.$el.find(`div.table-${tableId}`).siblings('div.modal').find(`[data-field=${fieldName}]`)[0].outerHTML
}
function insertNewTable(table,options){
	let el = options.el;
	let validationContext = options.context.validationContext;
	let op = fromRowViewItemsToOptions(table.rowViewItems,table)

	var func = app.userObservers.getRowClickFunction(table.fieldName)
	if (func) {
		table.onClickRow = (row, e) => {
			func.call(options.context, options.context.model, table.model.get(row.id))
		}
	}

	op.table={}
	table.tableOp=op

	if (table.isEmbedded){
		if(!func) {
			op.table.editable = table.editableOn;
			_.each(op.columns,(column)=>{
				table.setEditor(column)
			})
		}

		op.table.sortByRelativeOrder=true

		validationContext.addCollection(table.tableName, table.tableOp.columns, function (a) {
			table.showErrors.call(table, a)
		})
	}

	if (op.columns.length == 0 && !table.isEmbedded) {
		op.columns.unshift({
		field: 'id',
		title: translate('id'),
		isSystem: true,
		formatter: (f) => f,
			sorter: false
		})
	}
	if (!table.hideCheckbox){
		op.columns.unshift({type:'checkbox'})
	}
	table.columnsFull=op.columns
	op.columns=[]
	table.fillColumns()
	if (table.rowHeight) op.table.rowHeight = table.rowHeight
	if (table.headerRowHeight) op.table.headerRowHeight = table.headerRowHeight
	if (table.wrapHeaderText) op.table.wrapHeaderText = table.wrapHeaderText
	op.table.reorder=false
	op.table.id=table.id
	op.events = {
		selectionChanged: function (s) {
			table.onCheckAndUncheck();
		},
		afterRender: function () {
			table.$wrapper.find('.resize-observer object').attr('tabindex', '-1');
			buildDefaultPopover(table.$wrapper.find('.popover-tooltip'));
		},
		editModeExit: function () {
			table.onEditableAfterSave();
		}
	}

	op.componentKey = LocalStorageKeysService.buildKeyForComponent(table.id)
	if (table.onClickRow) {
		op.events.rowClicked = function (row, e) {
			table.onClickRow(row, e)
		}
		op.table.rowClasses = (item, index) => {
			return {
				clickable: 'clickable'
			}
		}
	}
	let connector =TableConnector(el, op).connect();
		 table.newTable=connector
	if (table.maxRowCount){
		let height = table.rowHeight || 40
		table.$wrapper.find('.virtual-scroller').attr('style',`max-height:${table.maxRowCount*height + 15}px;`)
	}
}
async function newTableInit(options,table){
	let errorHandler = (error) => {
		let tableRef = table
		if (error.status == '403'){
			$(tableRef.$el.attr('data-toolbar')).hide()
			tableRef.$wrapper.append(`<div style='width:100%;height:150px;border:2px solid #1F8DB8'><h4 style='padding:15px'>${app.getResource("do.not.have.permissions")}</h4></div>`)
		}else{
			throw error
		}
	}
	return getRowViewItems(table.viewId,table.typeId).then((rowViewItems)=>{
		table.rowViewItems=rowViewItems
		table.oldTable=getOldTableData(table.rowViewItems)
		initFiltersEntityTable({
			el: table._get('filters').first(),
			table: table,
			fields: app.fields,
			types: app.types,
			model: options.context.model,
			typeId: options.context.type.id
		});
		table.fieldsToShow = getFieldsToShow(rowViewItems)
		table.state = new TableState(table);
		table.state.apply();
		table.filterAndUpdate()
		insertNewTable(table, options)
		if (table.disabled){
			table.$wrapper.find('.cjg-table').attr('disabled','disabled')
		}
		options.context.control = options.context.control || {} // TODO: remove from here
		options.context.control[table.fieldId] = table
		addButtonsHandlers(table)
	}).catch(errorHandler)
}

var CollectionTable = Backbone.View.extend({

	baseClass: null,

	initialize: function (options) {
		this._options = options;
		this.isField = true;
		this.parentModel = this.model;
		if (!this.model.get(options.modelAttr)) {
			let val = this.baseClass.fromJSON([], this.typeId)
			this.model.set(options.modelAttr, val);
		}
		this.model = this.model.get(options.modelAttr);
    this.modelAttr = options.modelAttr
		this.fieldName = utils.getDataFieldAttr(this.$el);
		this.parent = options.parent;
		this.hasMetaObject=options.hasMetaObject
		this.treeViewKind = options.treeViewKind;
		this.viewId=options.viewId
		this.typeId=options.typeId
    this.context = options.context;
		this.tempel$=$(options.el)
		if (this.tempel$.attr('data-disabled') == "true"){
			this.disabled=true;
		}
		if (this.tempel$.attr('data-max-row-count')){
			this.maxRowCount=this.tempel$.attr('data-max-row-count');
		}
		if (this.tempel$.attr('data-row-height')) {
			this.rowHeight = this.tempel$.attr('data-row-height')
		}
		if (this.tempel$.attr('data-header-row-height')) {
			this.headerRowHeight = this.tempel$.attr('data-header-row-height')
		}
		if (this.tempel$.attr('data-wrap-header-text')) {
			this.wrapHeaderText = this.tempel$.attr('data-wrap-header-text')
		}
		if (this.tempel$.attr('data-hide-checkbox')){
			this.hideCheckbox=this.tempel$.attr('data-hide-checkbox');
		}
		if (this.tempel$.attr('data-hide-fillfunctions')){
			this.hideFillFunction=this.tempel$.attr('data-hide-fillfunctions');
		}
		if (this.tempel$.attr('data-link-form-view')){
			this.linkFormView=this.tempel$.attr('data-link-form-view');
		}
		if (this.tempel$.attr('data-open-link-in-same-window')){
			this.openLinkInSameWindow=this.tempel$.attr('data-open-link-in-same-window');
		}
		if (this.tempel$.attr('data-is-virtual')){
			this.viewId=this.tempel$.attr('data-view-id')
			this.typeId=this.tempel$.attr('data-entity-type-id')
		}
		this.isExternal = app.types.get(this.typeId).isExternal()
		this.fieldName=this.tempel$.attr('data-field')
		this.fieldId=this.tempel$.attr('data-field-id')
		if (this.tempel$.attr('data-is-embedded')=='true'){
			this.isEmbedded=true
		}else{
			this.isEmbedded=false
		}
    this.modalOpt = {
			modalWidth: this.tempel$.attr('data-modal-width'),
			modalHeight: this.tempel$.attr('data-modal-height'),
			modalFloat: this.tempel$.attr('data-modal-float')
		}
		this.id=this.tempel$.data('table-id')
		this.tempel$.wrap(`<div class='newTableWrapper table-${this.id}'><div>`)
		this.$wrapper = this.context.$el.find(`div.table-${this.id}`)
		this.data=[]
		this.listenTo(this.model, 'add', this.onModelRowAdded);
		this.listenTo(this.model, 'remove', this.modelRowRemoved);
		this.listenTo(this.model, 'change:clientState', this.stateChanged);
		this.listenTo(this.model, 'change', this.modelRowChanged);
		this.listenTo(this.model, 'reset', this.modelReseted)
	},
	async initializeTableAsync () {
		await newTableInit(this._options, this)
	},
	setEditor: function (column) {
		if (column.field && !(column.isReadOnly && column.readOnlyViewPlainText)){
			let html = getHTMLByFieldName(this.id, column.field, this.context)
			let $html = $(html)
			if (column.isReadOnly){
				html = $(html).attr('disabled','disabled')[0].outerHTML
      }
      if (column.dataSource){
        html = $(html).attr('data-source',column.dataSource).attr('in-table','true')[0].outerHTML
      }
			let type = 'custom'
			column.editor = { type: type, data: { disabled: column.isReadOnly, html: html, func: (a,b,c) => {
				initEditable.bind(this)(a,b,c,this)
			}}}
		} else {
			column.editor=null
		}
	},
	setFieldsToShow: function (fields) {
		this.fieldsToShow = fields
	},

	setWidth: function (fieldName, width) {
		setWidthFeature(this, fieldName, width)
	},
	setReadOnly: function(fieldName,state){
		let columnIndex=_.findIndex(this.tableOp.columns,(column) => {return column.field===fieldName})
		let column=this.tableOp.columns[columnIndex]
		column.isReadOnly=state
		this.setEditor(column)
		this.tableOp.columns.splice(columnIndex,1,column)
	},
	_get: function (shortId) {
		return this.context.$el.find('.table-' + this.id + '_' + shortId);
	},
	disable: function(){
		this._get('add').addClass('disabled')
		this._get('remove').addClass('disabled')
	},
	enable: function(){
		if (!this.disabled){
			this._get('add').removeClass('disabled')
			this._get('remove').removeClass('disabled')
		}
	},
	filter: function (filters, strict) {
		if (strict || this.filters && this.filters.getFilters().length === 0 && filters.length !== 0) {
			this.filters.loadFilters(filters);
		}
	},
	modelRowChanged: function (row) {
		let json = row.toJSON();
		json.id = this.getRowId(row)
		this._updateRow(json)
	},
	getSelectedIds: function(){
		return this.newTable.getSelection()
	},
	getSelectedRows: function(){
		const rows = [];
		const selection = this.newTable.getSelection();
		if (selection.length == 0) {
			return rows;
		}
		let rowMap = new Map();
		this.model.forEach(row => {
		  rowMap.set(row.getIdOrCid(), row);
		});
		selection.forEach(id => {
			const modelRow = rowMap.get(id)
			const clonedRow = _.extend({}, modelRow.attributes);
		  rows.push(clonedRow);
		});
		return rows;
	},
	onCheckAndUncheck: function () {
		if (this.newTable){
			var selectionLength = this.newTable.getSelection().length;
		}

		this._get('selectedEntities').text(selectionLength);

		if (selectionLength === 0) {
			this._get('selectedEntities').parent().attr('disabled', 'disabled');
		} else {
			this._get('selectedEntities').parent().removeAttr('disabled');
		}

	},

	onClickRow: function (row, $tr, column) {
	},

	getData: function() {
		return this.data
	},

	showColumnsList: function(e) {
		showColumnsList.call(this, e)
	},

	filterAndUpdate: function () {
		var collection = utils.filterCollection(this.model, this.filters.toJSON());
		this.data.splice(0, this.data.length)
		collection.forEach(item => this.modelRowAdded(item, false))
		this.filters.setFilteredLabelValue(collection.length)
	},
	getStorageId: function () {
		return this.id+'f';
	},

	setExtendedMode: function(){

	},

fillColumns: function () {
    this.tableOp.columns.splice(0, this.tableOp.columns.length)
    _.each(this.columnsFull, column => {
      if (this.fieldsToShow.includes(column.field) || column.type == 'checkbox'  || column.isSystem) {
        this.tableOp.columns.push(column)
      }
    })
},

	filtersChanged: function (event) {
		this.newTable && this.newTable.setSelection([])
		this.redraw()
	},
	onCollectionLoaded: function () {
		return false;
	},

	_removeRow (rowId) {
		let index =_.findIndex(this.data, row => row.id === rowId)
		if (index !== -1) {
			this.data.splice(index, 1)
		}
	},

  _updateRow (object) {
    let existing = this.data.find(row => row.id === object.id)
    if (existing) {
      Object.assign(existing, object)
    }
  },

  _updateModel (data) {
    this.model.get(data.item.id).merge(data.item, {});
  },

  stateChanged: function (model, clientState) {
    if (clientState === States.DELETED) {
      this.modelRowRemoved(model);
    }
  },

	redraw: function(){
		this.filterAndUpdate()
		this.newTable && this.newTable.setOrder(this.newTable.getOrder())
	},
	reload: function(){
		this.redraw()
	},

	onModelRowAdded: function (row) {
		this.modelRowAdded(row, true)
	},

	modelRowAdded: function (row, doUnshift) {
		if (row.get('isReference')) {
			app.entityManager.fetchById(this.typeId, row.id).then((raw) => {
				this.rawRowAdd(raw, doUnshift)
			})
		} else {
			let raw = row.toJSON();
			raw.id = this.getRowId(row)
			this.rawRowAdd(raw, doUnshift)
		}
	},

	rawRowAdd: function (raw, doUnshift) {
		if (doUnshift) {
			this.data.unshift(raw)
		}
		else {
			this.data.push(raw)
		}
		this.filters.setFilteredLabelValue(this.data.length)
	},

	getRowId (row) {
		return row.getIdOrCid()
	},

	modelRowRemoved: function (row) {
		this._removeRow(this.getRowId(row))
	},
	clearFilters: function () {
		this.state.clearFiltersAndOrder();
		this.removeFilters();
		this.redraw();
	},
	removeFilters: function () {
		this.filters && this.filters.removeFilters();
	},
	enableReorderable: function () {
		this._get('cancelReorder').show();
		this._get('saveOrder').show();
		this._get('reorder').hide();
		this.filters && this.filters.disableRemoveFilter();
		this._get('filters').find('.add-filter').attr('disabled', 'disabled');
		this.tableOp.table.reorder = true
	},
	disableReorderable: function () {
		this.tableOp.table.reorder = false;
		this.filters && this.filters.allowRemoveFilter();
		this._get('cancelReorder').hide();
		this._get('saveOrder').hide();
		this._get('reorder').show();
		this._get('filters').find('.add-filter').removeAttr('disabled');
	},
	onEditableAfterSave: function () {
	},
	modelReseted () {
		let removeRows = []
		this.data.forEach(data =>{
			let found = this.model.find(model => data.id === model.getIdOrCid())
			if (!found){
				removeRows.push(data.id)
			}
		})
		removeRows.forEach(rowId => this._removeRow(rowId))
		if (this.filters.getFilters().size() === 0) {
			// add to this.data only if filters are empty

			let rowIds = [];
			this.data.forEach(row => rowIds.push(row.id));

			this.model.forEach(item => {
				let found = rowIds.find(rid => rid === item.getIdOrCid());
				if (!found) {
					this.modelRowAdded(item, false);
				}
			})
		}
		this.filters.setFilteredLabelValue(this.data.length)
		this.redraw()
	}
});

export default CollectionTable;
