/* @flow */
import long from 'int';

import ViewKind from '../../common/enums/viewKind.js';

const ID_SEQUENCE_OFFSET = long(2).pow(47);

export default class Constants {

		static SHORT_STRING_MAX_LENGTH = Math.pow(2, 7);
		static STRING_MAX_LENGTH = Math.pow(2, 14);

	/* Types */
		static ID_SEQUENCE_OFFSET = ID_SEQUENCE_OFFSET;
		static ID_TYPE_TYPE = ID_SEQUENCE_OFFSET.add('100').toString();
		static ID_TYPE_FIELD = ID_SEQUENCE_OFFSET.add('101').toString();
		static ID_TYPE_META_OBJECT = ID_SEQUENCE_OFFSET.add('102').toString();
		static ID_TYPE_STRING = ID_SEQUENCE_OFFSET.add('103').toString();
		static ID_TYPE_MULTILINGUAL_STRING = ID_SEQUENCE_OFFSET.add('104').toString();
		static ID_TYPE_INTEGER_NULLABLE = ID_SEQUENCE_OFFSET.add('105').toString();
		static ID_TYPE_DECIMAL2 = ID_SEQUENCE_OFFSET.add('106').toString();
		static ID_TYPE_BOOLEAN = ID_SEQUENCE_OFFSET.add('107').toString();
		static ID_TYPE_TIMESTAMP = ID_SEQUENCE_OFFSET.add('108').toString();
		static ID_TYPE_LANGUAGE = ID_SEQUENCE_OFFSET.add('109').toString();
		static ID_TYPE_USER_ACCOUNT = ID_SEQUENCE_OFFSET.add('110').toString();
		static ID_TYPE_USER_ROLE = ID_SEQUENCE_OFFSET.add('111').toString();
		static ID_TYPE_TASK = ID_SEQUENCE_OFFSET.add('112').toString();
		static ID_TYPE_TIME_ZONE = ID_SEQUENCE_OFFSET.add('113').toString();
		static ID_TYPE_ZONED_DATE_TIME = ID_SEQUENCE_OFFSET.add('114').toString();
		static ID_TYPE_SYSTEM_INTEGER = ID_SEQUENCE_OFFSET.add('115').toString();
		static ID_TYPE_PERIOD = ID_SEQUENCE_OFFSET.add('116').toString();
		static ID_TYPE_DURATION = ID_SEQUENCE_OFFSET.add('117').toString();
		static ID_TYPE_LOCAL_DATE = ID_SEQUENCE_OFFSET.add('118').toString();
		static ID_TYPE_LOCAL_TIME = ID_SEQUENCE_OFFSET.add('119').toString();
		static ID_TYPE_LOCAL_DATE_TIME = ID_SEQUENCE_OFFSET.add('120').toString();
		static ID_TYPE_MONTH = ID_SEQUENCE_OFFSET.add('121').toString();
		static ID_TYPE_MONTH_DAY = ID_SEQUENCE_OFFSET.add('122').toString();
		static ID_TYPE_YEAR = ID_SEQUENCE_OFFSET.add('123').toString();
		static ID_TYPE_YEAR_MONTH = ID_SEQUENCE_OFFSET.add('124').toString();
		static ID_TYPE_ZONE_OFFSET = ID_SEQUENCE_OFFSET.add('125').toString();
		static ID_TYPE_APP_USER_ACCOUNT = ID_SEQUENCE_OFFSET.add('126').toString();
		static ID_TYPE_APP_LANGUAGE = ID_SEQUENCE_OFFSET.add('127').toString();
		static ID_TYPE_REPORT_GROUP = ID_SEQUENCE_OFFSET.add('128').toString();
		static ID_TYPE_REPORT_VALUE = ID_SEQUENCE_OFFSET.add('129').toString();
		static ID_TYPE_FIELD_FILTER = ID_SEQUENCE_OFFSET.add('130').toString();
		static ID_TYPE_FIELD_ORDER = ID_SEQUENCE_OFFSET.add('131').toString();
		static ID_TYPE_CHRONO_FIELD = ID_SEQUENCE_OFFSET.add('132').toString();
		static ID_TYPE_VIEW = ID_SEQUENCE_OFFSET.add('133').toString();
		static ID_TYPE_BLOCK = ID_SEQUENCE_OFFSET.add('134').toString();
		static ID_TYPE_REGISTER_INDEX = ID_SEQUENCE_OFFSET.add('135').toString();
		static ID_TYPE_AGGREGATE_KIND = ID_SEQUENCE_OFFSET.add('136').toString();
		static ID_TYPE_FILTER_OPERATOR = ID_SEQUENCE_OFFSET.add('137').toString();
		static ID_TYPE_FILTER_KIND = ID_SEQUENCE_OFFSET.add('138').toString();
		static ID_TYPE_DOUBLE = ID_SEQUENCE_OFFSET.add('139').toString();
		static ID_TYPE_CONFIGURATION = ID_SEQUENCE_OFFSET.add('141').toString();
		static ID_TYPE_FOLDER = ID_SEQUENCE_OFFSET.add('142').toString();
		static ID_TYPE_CATEGORY = ID_SEQUENCE_OFFSET.add('143').toString();
		static ID_TYPE_SHARED_STYLE = ID_SEQUENCE_OFFSET.add('144').toString();
		static ID_TYPE_PERMISSION = ID_SEQUENCE_OFFSET.add('145').toString();
		static ID_TYPE_ENTITY_MENU = ID_SEQUENCE_OFFSET.add('146').toString();
		static ID_TYPE_ENTITY_GENERATOR = ID_SEQUENCE_OFFSET.add('147').toString();
		static ID_TYPE_DOCUMENT_TIMELINE = ID_SEQUENCE_OFFSET.add('148').toString();
		static ID_TYPE_MULTILINGUAL_STRING_RESOURCE = ID_SEQUENCE_OFFSET.add('149').toString();

		static ID_TYPE_PRIMITIVE_TYPE_PROPERTIES = ID_SEQUENCE_OFFSET.add('150').toString();
		static ID_TYPE_VIEW_ITEM_PROPERTY = ID_SEQUENCE_OFFSET.add('151').toString();
		static ID_TYPE_ENTITY_MENU_ITEM = ID_SEQUENCE_OFFSET.add('152').toString();
		static ID_TYPE_PERMISSION_FILTER = ID_SEQUENCE_OFFSET.add('153').toString();
		static ID_TYPE_COMPRESSED_LOB = ID_SEQUENCE_OFFSET.add('154').toString();
		static ID_TYPE_WORKSPACE_SNAPSHOT = ID_SEQUENCE_OFFSET.add('155').toString();
		static ID_TYPE_ENTITY_VIEW_ROW_ITEM = ID_SEQUENCE_OFFSET.add('156').toString();
		static ID_TYPE_ENTITY_VIEW_FORM_ITEM = ID_SEQUENCE_OFFSET.add('157').toString();
		static ID_TYPE_ENTITY_VIEW_STRING_ITEM = ID_SEQUENCE_OFFSET.add('158').toString();
		static ID_TYPE_PRIMITIVE_FORMATTER = ID_SEQUENCE_OFFSET.add('159').toString();
		static ID_TYPE_REGISTER_INDEX_FIELD = ID_SEQUENCE_OFFSET.add('160').toString();
		static ID_TYPE_FILE_INFO = ID_SEQUENCE_OFFSET.add('161').toString();
		static ID_TYPE_SYSTEM_INSTANCE = ID_SEQUENCE_OFFSET.add('162').toString();
		static ID_TYPE_TRANSPILED_CODE = ID_SEQUENCE_OFFSET.add('163').toString();
		static ID_TYPE_SCHEDULER = ID_SEQUENCE_OFFSET.add('164').toString();
		static ID_TYPE_DOCUMENT_REGISTER_STATE = ID_SEQUENCE_OFFSET.add('165').toString();
		static ID_TYPE_BINARY = ID_SEQUENCE_OFFSET.add('166').toString();
		static ID_TYPE_ENTITY_GENERATOR_STATE = ID_SEQUENCE_OFFSET.add('167').toString();
		static ID_TYPE_FIELD_VALUE = ID_SEQUENCE_OFFSET.add('168').toString();
		static ID_TYPE_DOCUMENT_REGISTER = ID_SEQUENCE_OFFSET.add('169').toString();
		static ID_TYPE_CUSTOM_LIBRARY = ID_SEQUENCE_OFFSET.add('170').toString();
		static ID_TYPE_CONFIGURATION_BUILD = ID_SEQUENCE_OFFSET.add('171').toString();
		static ID_TYPE_INSTANCE_JSON_VALUE = ID_SEQUENCE_OFFSET.add('172').toString();
		static ID_TYPE_TASK_MESSAGE = ID_SEQUENCE_OFFSET.add('173').toString();
		static ID_TYPE_BUILDER = ID_SEQUENCE_OFFSET.add('174').toString();
		static ID_TYPE_PERMISSIONS_CONFIG = ID_SEQUENCE_OFFSET.add('175').toString();
		static ID_TYPE_EVENT_HANDLER = ID_SEQUENCE_OFFSET.add('176').toString();
		static ID_TYPE_INTEGER = ID_SEQUENCE_OFFSET.add('177').toString();
		static ID_TYPE_SYSTEM_INTEGER_NULLABLE = ID_SEQUENCE_OFFSET.add('178').toString();
		static ID_TYPE_ENTITY_VIEW_FORM_PROPERTIES = ID_SEQUENCE_OFFSET.add('179').toString();
		static ID_TYPE_ENTITY_VIEW_SPREADSHEET_PROPERTIES = ID_SEQUENCE_OFFSET.add('180').toString();
		static ID_TYPE_ENTITY_VIEW_XML_PROPERTIES = ID_SEQUENCE_OFFSET.add('181').toString();
		static ID_TYPE_API_ENTRY = ID_SEQUENCE_OFFSET.add('182').toString();
		static ID_TYPE_PERMISSION_NETWORK_WHITELIST = ID_SEQUENCE_OFFSET.add('183').toString();
		static ID_TYPE_META_OBJECT_STATE = ID_SEQUENCE_OFFSET.add('184').toString();
		static ID_TYPE_CLEANER_REPORT_SOURCE = ID_SEQUENCE_OFFSET.add('185').toString();
		static ID_TYPE_CLEANER_REPORT_SOURCE_COLLECTION = ID_SEQUENCE_OFFSET.add('186').toString();
		static ID_TYPE_CLEANER_REPORT = ID_SEQUENCE_OFFSET.add('187').toString();
		static ID_TYPE_LOCAL_DATE_RANGE = ID_SEQUENCE_OFFSET.add('188').toString();
		static ID_TYPE_ENTITY_ROUTE = ID_SEQUENCE_OFFSET.add('189').toString();
		static ID_TYPE_MULTILINGUAL_STRING_NO_TRANSLATIONS = ID_SEQUENCE_OFFSET.add('190').toString();
		static ID_TYPE_HTML_STRING = ID_SEQUENCE_OFFSET.add('191').toString();
		static ID_TYPE_HTML_STRING_NO_TRANSLATIONS = ID_SEQUENCE_OFFSET.add('192').toString();
		static ID_TYPE_CSS_THEME = ID_SEQUENCE_OFFSET.add('195').toString();
		static ID_TYPE_ENTITY_VIEW_DOC_PROPERTIES = ID_SEQUENCE_OFFSET.add('196').toString();
		static ID_TYPE_SEO_DATA = ID_SEQUENCE_OFFSET.add('197').toString();
		static ID_TYPE_LANGUAGE_VERSION = ID_SEQUENCE_OFFSET.add('198').toString();
		static ID_TYPE_MODULE = ID_SEQUENCE_OFFSET.add('199').toString();

		/* Fields */
		static ID_FIELD_ID = ID_SEQUENCE_OFFSET.add('200').toString();
		static ID_FIELD_NAME = ID_SEQUENCE_OFFSET.add('201').toString();
		static ID_FIELD_DOCUMENT_DATE_AND_TIME = ID_SEQUENCE_OFFSET.add('202').toString();
		static ID_FIELD_RELATIVE_ORDER = ID_SEQUENCE_OFFSET.add('203').toString();
		static ID_FIELD_REGISTRAR = ID_SEQUENCE_OFFSET.add('204').toString();
		static ID_FIELD_APPLICATION_INSTANCE = ID_SEQUENCE_OFFSET.add('205').toString();
		static ID_FIELD_LANGUAGE_TAG = ID_SEQUENCE_OFFSET.add('206').toString();
		static ID_FIELD_SERIAL_NUMBER = ID_SEQUENCE_OFFSET.add('207').toString();

		static ID_FIELD_TASK_TIME_RECEIVED = ID_SEQUENCE_OFFSET.add('208').toString();
		static ID_FIELD_TASK_TIME_STARTED = ID_SEQUENCE_OFFSET.add('209').toString();
		static ID_FIELD_TASK_TIME_DONE = ID_SEQUENCE_OFFSET.add('210').toString();
		static ID_FIELD_TASK_RESULT = ID_SEQUENCE_OFFSET.add('211').toString();
		static ID_FIELD_TASK_KIND = ID_SEQUENCE_OFFSET.add('212').toString();
		static ID_FIELD_TASK_STATE = ID_SEQUENCE_OFFSET.add('213').toString();

		static ID_FIELD_TIME_ZONE_ID = ID_SEQUENCE_OFFSET.add('214').toString();
		static ID_FIELD_FIELD = ID_SEQUENCE_OFFSET.add('215').toString();
		static ID_FIELD_INCLUDE_FOLDERS = ID_SEQUENCE_OFFSET.add('216').toString();
		static ID_FIELD_TEMPORAL_UNIT = ID_SEQUENCE_OFFSET.add('217').toString();
		static ID_FIELD_NUMBER_UNIT = ID_SEQUENCE_OFFSET.add('218').toString();
		static ID_FIELD_REPORT_ROW_GROUPS = ID_SEQUENCE_OFFSET.add('219').toString();
		static ID_FIELD_REPORT_COLUMN_GROUPS = ID_SEQUENCE_OFFSET.add('220').toString();
		static ID_FIELD_AGGREGATE_KIND = ID_SEQUENCE_OFFSET.add('221').toString();
		static ID_FIELD_REPORT_VALUES = ID_SEQUENCE_OFFSET.add('222').toString();
		static ID_FIELD_FILTER_OPERATOR = ID_SEQUENCE_OFFSET.add('223').toString();
		static ID_FIELD_FILTER_KIND = ID_SEQUENCE_OFFSET.add('224').toString();
		static ID_FIELD_FILTER_ID = ID_SEQUENCE_OFFSET.add('225').toString();
		static ID_FIELD_FILTER_VALUE = ID_SEQUENCE_OFFSET.add('226').toString();
		static ID_FIELD_TIME_ZONE = ID_SEQUENCE_OFFSET.add('231').toString();
		static ID_FIELD_FIELD_FILTERS = ID_SEQUENCE_OFFSET.add('232').toString();
		static ID_FIELD_INCLUDE_TOTAL = ID_SEQUENCE_OFFSET.add('233').toString();
		static ID_FIELD_OWNER = ID_SEQUENCE_OFFSET.add('234').toString();
		static ID_FIELD_IS_FOLDER = ID_SEQUENCE_OFFSET.add('235').toString();
		static ID_FIELD_TREE_PARENT = ID_SEQUENCE_OFFSET.add('236').toString();
		static ID_FIELD_TREE_CHILDREN = ID_SEQUENCE_OFFSET.add('237').toString();
		static ID_FIELD_META_OBJECT = ID_SEQUENCE_OFFSET.add('238').toString();
		static ID_FIELD_LANGUAGE = ID_SEQUENCE_OFFSET.add('239').toString();
		static ID_FIELD_SHARED_FIELDS = ID_SEQUENCE_OFFSET.add('240').toString();
		static ID_FIELD_ENTITY = ID_SEQUENCE_OFFSET.add('241').toString();
		static ID_FIELD_BLOCK = ID_SEQUENCE_OFFSET.add('242').toString();
		static ID_FIELD_TYPE = ID_SEQUENCE_OFFSET.add('243').toString();
		static ID_FIELD_CRON_EXPRESSION = ID_SEQUENCE_OFFSET.add('244').toString();
		static ID_FIELD_DOCUMENT_REGISTER_STATE = ID_SEQUENCE_OFFSET.add('245').toString();
		static ID_FIELD_USER_ACCOUNT = ID_SEQUENCE_OFFSET.add('246').toString();
		static ID_FIELD_USER_ROLES = ID_SEQUENCE_OFFSET.add('247').toString();
		static ID_FIELD_CLEANER_REPORT_SOURCE_OBJECT_ID = ID_SEQUENCE_OFFSET.add('248').toString();
		static ID_FIELD_CLEANER_REPORT_SOURCE_TYPE = ID_SEQUENCE_OFFSET.add('249').toString();
		static ID_FIELD_CLEANER_REPORT_SOURCE_STATE = ID_SEQUENCE_OFFSET.add('250').toString();
		static ID_FIELD_CLEANER_REPORT_SOURCE_TIMESTAMP = ID_SEQUENCE_OFFSET.add('251').toString();
		static ID_FIELD_CLEANER_REPORT_SOURCE_COLLECTION_ITEMS = ID_SEQUENCE_OFFSET.add('252').toString();
		static ID_FIELD_CLEANER_REPORT_TYPE = ID_SEQUENCE_OFFSET.add('253').toString();
		static ID_FIELD_CLEANER_REPORT_STATE = ID_SEQUENCE_OFFSET.add('254').toString();
		static ID_FIELD_CLEANER_REPORT_TIMESTAMP = ID_SEQUENCE_OFFSET.add('255').toString();


		static ID_GENERATED_VIEW = {
			[ViewKind.STRING]: ID_SEQUENCE_OFFSET.add('300').toString(),
			[ViewKind.ROW]: ID_SEQUENCE_OFFSET.add('301').toString(),
			[ViewKind.FORM]: ID_SEQUENCE_OFFSET.add('302').toString(),
		};

		static ID_GENERATED_SHARED_VIEW = {
			[ViewKind.ROW]: ID_SEQUENCE_OFFSET.add('310').toString()
		};

		static ID_VIEW_CLEANER_REPORT = ID_SEQUENCE_OFFSET.add('311').toString();

		static USER_ROLE_ADMIN_NAME = "system.user.role.admin.name";

		static ID_USER_ROLE = {
			[Constants.USER_ROLE_ADMIN_NAME]: ID_SEQUENCE_OFFSET.add('500').toString()
		};
		static BUILDER_RANGE_START = long(2).pow(61).toString()
		static BUILDER_RANGE_END = long(2).pow(62).add(-1).toString()
		static APPLICATION_RANGE_START = long(2).pow(62).toString()
		static APPLICATION_RANGE_END = long(2).pow(63).add(-1).toString()


		static ID_FORMATTER_EMAIL = ID_SEQUENCE_OFFSET.add('2250').toString();
		static ID_FORMATTER_LINK = ID_SEQUENCE_OFFSET.add('2251').toString();

		static ID_TYPE_MODULE = ID_SEQUENCE_OFFSET.add('199').toString();
		static ID_TYPE_JSON_NODE = ID_SEQUENCE_OFFSET.add('3000');
		static ID_TYPE_HASH_MAP = ID_SEQUENCE_OFFSET.add('3007');
		static ID_TYPE_DATASOURCE_CONFIG = ID_SEQUENCE_OFFSET.add('3008');
		static ID_TYPE_FIELD_JOIN_MAP = ID_SEQUENCE_OFFSET.add('3009');
		static ID_TYPE_NOTIFICATIONS = ID_SEQUENCE_OFFSET.add('3010');
		static ID_TYPE_REQUEST_INFO = ID_SEQUENCE_OFFSET.add('3011');

		static isPredefined = (id) => {
			return ID_SEQUENCE_OFFSET.lte(id) && ID_SEQUENCE_OFFSET.mul(2).gte(id);
		}

}
