import Entity from '../../common/models/entity.js';
import WizardView from './wizardView.js';
import entityManager from '../../common/components/entityManager.js';
import HistoryView from './historyView';
import ValidationContext from '../../common/components/validationContext'
import MetaObjectState from '../../common/enums/metaObjectState';
import ViewKind from '../../common/enums/viewKind';
import PropertyKey from '../../common/enums/propertyKey';
import { initialize } from '../../common/general';
import Constants from '../../common/models/constants';
import utils from '../../common/components/utils';
import {scaleDecimals, unscaleDecimals} from '../../common/components/utils';
import { getScript } from '../../common/components/utils'
import Workspace from '../../applicationIndex/workspace'
import ErrorCode from '../../common/enums/errorCode'
import PrimitiveEntityType from '../../common/enums/primitiveEntityType';
import IndexView from '../../entity/views/indexView.js';
import MultilingualString from '../../common/models/multilingualString.js';
import PropertiesCollection from '../../EntityView/models/properties/propertiesCollection'
import {FieldService} from "../../common/service/fieldService";
import { ApplicationStatePrototype } from '../../common/components/applicationState';
import LocalStorageKeysService from '../../common/service/localStorageKeysService'
import StateRecovery from '../../common/components/stateRecovery.js'
import ViewContext from '../../common/models/viewContext.js'
import FieldFilter from '../../common/models/fieldFilter.js'
import { translate } from '../../common/service/stringResourceService'

const getNextContextId = (() => {
	let lastId = 0;
	return () => ++lastId;
})();

class PresentationContext {
	constructor(...options) {
		options && options.forEach(o => _.extend(this, o));
		this.id = getNextContextId();
		let formsContainer = this.$el.find('.forms-container')
		if (formsContainer.length == 0) formsContainer = this.$el
		this.validationContext = new ValidationContext({
			el: formsContainer,
			context: this
		});
		this.wizardView = new WizardView({
			el: this.$el,
			context: this
		});
		this.historyView = this.canViewHistory && new HistoryView({
			context: this
		});
		this.onClose = this.onClose
			|| (() => utils.redirectTo(app.urls.indexPage(this.type.id)));
		this.fieldService = new FieldService(this);
		this.applicationState = new ApplicationStatePrototype()
	}

	destroy() {
		this.wizardView.destroy();
		this.validationContext.destroy();
		this.historyView && this.historyView.destroy();
	}

	update() {
		this.historyView && this.historyView.update();
	}

	getServerData() {
		let data = this.model.toServerJSON(app.builderMode || this.type.isExternal() ? null : this.data)
		if (!app.builderMode) {
			unscaleDecimals(this.type, data)
		}
		return data
	}
}


class EntityPresenter {

	constructor() {
		this.loaded = {
			customJs: new Set(),
			customCss: new Set(),
			blocksJs: new Set(),
			jsFilesUsedInLoaded: new Set()
		};
	}

	_addCustomCss(files) {
		files.filter(f => !this.loaded.customCss.has(f))
			.forEach(f => {
				let link = document.createElement("link")
				link.rel = "stylesheet";
				link.type = "text/css";
				link.href = f;
				document.head.appendChild(link);
				this.loaded.customCss.add(f);
			});
	}

	_addCustomJs(files) {
		files.filter(f => !this.loaded.customJs.has(f))
			.forEach(f => {
				let script = document.createElement('script');
				script.type = "text/javascript";
				script.src = f;
				document.head.appendChild(script);
				this.loaded.customJs.add(f);
			});
	}

	_loadCustomJs(files) {
		return Promise.all((files || [])
			.filter(d => !this.loaded.jsFilesUsedInLoaded.has(d))
			.map(d => getScript(d).then(() => this.loaded.jsFilesUsedInLoaded.add(d))));
	}

	_loadBlocksJs(dependencies) {
		return Promise.all((dependencies || [])
			.filter(d => !this.loaded.blocksJs.has(d.id))
			.map(d => getScript(d.url).then(() => this.loaded.blocksJs.add(d.id))));
	}

	_checkMissingFields($el, type) {
		const missingFields = $el.find('[data-field]').get()
			.filter(x => {
				const $x = $(x);
				const emb = $x.parents('.modal[data-is-embedded="true"]');
				const owner = emb.length
					? app.types.get(emb.data('entityTypeId'))
					: type;
				return !owner.fieldByName($x.data('field'));
			});
		if (missingFields.length) {
			throw new Error(ErrorCode.META_DATA_IS_STALE);
		}
	}

	present(options) {
		return utils.request(null, options.url, 'GET')
			.then(info => {
				if (info.redirectUrl) {
						utils.redirectTo(info.redirectUrl, true);
						options.viewControl && options.viewControl.close();
						return ;
				}
				if (info.isIndex) {
					return this._presentIndex(info, options)
				} else {
					return this._presentForm(info, options);
				}
			});
	}

	_loadFiles(info, ignoreBlocks) {
		this._addCustomCss(info.cssFiles);
		this._addCustomJs(info.jsFiles);
		let result = this._loadCustomJs(info.jsFilesUsedInLoaded)
		if (!ignoreBlocks) {
			result = Promise.all([result, this._loadBlocksJs(info.dependenciesForBlocks)]);
		}
		return result;
	}

	_presentForm(info, options) {
		const filesPromise = options.filesPromise || this._loadFiles(info, options.ignoreBlocks);
		const type = app.types.get(info.typeId);
		const $el = $(options.el);
		return new Workspace().createUpdateInstance($el, info.htmlUrl, options.html)
			.then(() => app.builderMode && this._checkMissingFields($el, type))
			.then(options.afterFormLoaded || (() => {}))
			.then((resp) => {
				let viewContext = new ViewContext()
				let filters = $el.find('.data-table').toArray().map((elem) => {
					const id = elem.dataset['tableId']
					const isVirtual = elem.dataset['isVirtual'] == "true"
					const typeId = elem.dataset['entityTypeId']
					let key = id + "f"
					if (isVirtual) {
						key = typeId + "_" + id + "f"
					}
					const localStorageKey = LocalStorageKeysService.buildCollectionKey(key)
					const json = StateRecovery.get(localStorageKey)
					if (json != null && json.filters != null) {
						let filts = []
						json.filters.forEach((f) => {
							let ff = new FieldFilter()
							if (f.op) {
								ff.set('op', f.op)
							} else {
								const field = app.fields.get(f.field.id)
								ff.set('field', field)
								ff.set('kind', f.kind)
								ff.set('value', FieldFilter.valueFromJSON(field.type(), f.value))
							}
							ff.set('readOnly', f.readOnly)
							ff.set('isVisible', f.isVisible)
							filts.push(ff)
						})
						viewContext.setFilters(id.split('-')[0], filts)
					}
					return {
						id: id,
						state: json
					}
				})
				if (options.isWidget) {
					return options.widgetModel;
				} else {
					let dataUrl = app.urls.data(info.typeId, info.objectId, {
						viewId: !app.builderMode && info.viewId,
						fillFunctionId: info.fillFunctionId,
						fillInstanceId: info.fillInstanceId
					});
					if (app.builderMode) {
						return utils.getRequest(dataUrl)
					} else {
						return utils.postRequest(viewContext.toJSON(), dataUrl).catch((a) => {
 							app.notificationManager.addError(translate('problem.occurred.receive.data.from.server'))
							// stub with empty data to continue page load (page without data with error messages 
							// is better than endless spinner without messages)
							return Promise.resolve({
								item: null,
								modifiedItem: null
							})
						})
					}
					
				}
			})
			.then(resp => {
				let model
				let data = resp.item;
				const modifiedData = resp.modifiedItem
				if (options.isWidget) {
					model = options.model
				} else {
					if (!app.builderMode){
						scaleDecimals(type, data)
					}
					if (!app.builderMode){
						scaleDecimals(type, modifiedData)
					}
						model = _.isString(data)
						? Entity.fromJSON(JSON.parse(data), type.id)
						: Entity.fromJSON(data, type.id);
					if (modifiedData) {
						model.merge(modifiedData, data);
					}
				}
				const preventPageLeave = options.preventPageLeave == null
						? info.preventPageLeave
						: options.preventPageLeave;

				const ctx = new PresentationContext(
					_.omit(options, 'afterFormLoaded'), {
						type, data, modifiedData, $el, model, preventPageLeave,
						themeUrl: info.themeUrl,
						objectId: info.objectId,
						viewId: info.viewId,
						loadedBlockId: info.loadedBlockId,
						fillFunctions: info.fillFunctions,
						parentId: info.parentId,
						showToolbar: info.showToolbar,
						title: info.title,
						updating: info.updating,
						pageInEditMode: info.pageInEditMode,
						isSystem: info.isSystem,
						canUpdate: info.canUpdate,
						canViewHistory: info.canViewHistory,
						typeId: info.typeId,
						flowId: info.flowId
					}
				);

				if (ctx.historyView && model.get('metaObjectState') == MetaObjectState.DELETED) {
					ctx.historyView.showHistory();
				}

				ctx.wizardView.render();
				const asyncInit = ctx.wizardView.initializeAsyncComponents();
				if (!app.builderMode){
					if (!options.ignoreBlocks) {
						Promise.all([asyncInit, filesPromise])
						.then(() => {
							app.userObservers.initializeSubscriptions(ctx);
							if (model.id) {
								entityManager.invokePageLoad(ctx);
							} else {
								entityManager.invokeClientFormLoad(ctx)
								.then(() => {
									return entityManager.invokePageLoad(ctx);
								});
							}
						});
					}
				}
				if (window.getSelection)
					window.getSelection().removeAllRanges()
				else if (document.selection)
					document.selection.empty()
				return ctx;
			});
	}


	_presentIndex(info, options) {
		this._loadFiles(info, options.ignoreBlocks);
		const type = app.types.get(info.typeId);
		const $el = $(options.el);
		app.listView = info.listViewModel
		let listProperties = (app.listView && app.listView.properties.length && app.listView.properties) ? new PropertiesCollection(app.listView.properties) : null
		return new Workspace().typeIndex({
				$el: $el,
				hasMetaObject: type.hasMetaObject(),
				entityTypeName: new MultilingualString(type.name()).getCurrentValue(),
				canViewHistory: info.canViewHistory,
				canCreate:  options.canCreate === false ? false : info.canCreate,
				isDocuments: type.isDocument(),
				isExternal: type.isExternal(),
				hideSelection: options.hideSelection,
				hideContextMenu: options.hideContextMenu,
				showConfirmSelect: options.showConfirmSelect,
				listProperties: listProperties,
				sameTypeAsDefaultRoute: info.sameTypeAsDefaultRoute
			}, app.urls.indexTemplate)
			.then(options.afterFormLoaded || (() => {}))
			.then(() => {
				const ctx = {
					destroy: ()=>{
						$el.html('')
					},
					update:() =>{
						$('#wrapper').removeClass('toggled');
					},
					$el: $el,
					type: type,
					themeUrl: info.themeUrl,
					fillFunctions: info.fillFunctions,
					clickCallback: options.clickCallback,
					openInstancesMode: info.openInstancesMode,
					title: info.title,
					applicationState: new ApplicationStatePrototype()
				}

				var indexView = new IndexView({
					el: $el,
					typeId: info.typeId,
					viewId: info.viewId,
					isDocuments: type.isDocument(),
					hasMetaObject: type.hasMetaObject(),
					treeViewKind: type.treeViewKind(),
					hideCheckbox: options.hideSelection,
					extendedView: options.extendedView,
					context: ctx,
					filters: options.filters,
					selected: options.selected,
					onConfirmSelect: options.onConfirmSelect,
					listProperties: listProperties
				})
				indexView.initializeAsyncComponents()
				indexView.render();
				options.hideLoading && options.hideLoading()
				return ctx
			});
	}
};

export default new EntityPresenter();
